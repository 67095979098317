import Title from 'antd/es/typography/Title'
import Text, { TextProps } from 'antd/es/typography/Text'
import { moment } from '../ui-elements/FormDate'
import { Comment } from '@ant-design/compatible';
import { Space, Row, Col, Spin, List } from 'antd';
import { useSelector } from '../../../hooks/useSelector'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { dispatch } from '../../../store/store'
import React, { FC, useEffect, useState, useRef, forwardRef } from 'react'
import styled from 'styled-components'
import KycDocsInfo from '../KycDocsInfo'
import { KycDocumentsSteps } from '../KycDocumentsSteps';
import KycNextSteps from '../KycNextSteps'
import { themeColor, themeSize } from '../../../styles/themeStyles'
import KycLayout, { NavItem } from '../kyc-layout/KycLayout'
import { profileGet } from  '../../../store/kyc-front/asyncThunks/profileGet'
import { individualGet } from '../../../store/kyc-front/asyncThunks/individualGet'
import { companyMessagesGet } from '../../../store/kyc-front/asyncThunks/companyMessagesGet'
import { companyMessageViewedPut } from '../../../store/kyc-front/asyncThunks/companyMessageViewedPut'
import { companyMessagePost } from '../../../store/kyc-front/asyncThunks/companyMessagePost'
import { individualMessagePost } from '../../../store/kyc-front/asyncThunks/individualMessagePost'
import { individualMessagesGet } from '../../../store/kyc-front/asyncThunks/individualMessagesGet'
import { individualMessageViewedPut } from '../../../store/kyc-front/asyncThunks/individualMessageViewedPut'
import { individualMessageViewedPutClear, companyMessageViewedPutClear, companyMessagesGetClear, individualMessagesGetClear, companyMessagePostClear, individualMessagePostClear } from '../../../store/kyc-front/slices/messagesSlice'
import { MessagesListing } from '../ui-elements/Comment'


const TextMain = styled(Text)`
	font-size: ${themeSize.heading5};
	line-height: 32px;
`

const CommentCustom = styled.div`
	margin-top: -40px;
	.ant-list-header {
		text-align: right;
		color: ${themeColor.gray}
	}
	.ant-list-items {
		.ant-comment {
	        border-bottom: 1px solid ${themeColor.grayWhite};
	        &:last-child{
	        	border: none;
	        }
	    }
	}
    
`


const KycInbox: FC = () => {
  	const envs = useSelector((state) => state.general.envs);
  	const messages = useSelector((state) => state.user.messages);
    let isBusiness = envs.accountType === 'BUSINESS';
  	useEffect(() => {
      		if(isBusiness) {
        		dispatch(companyMessagesGet({params: {companyId: envs.profileId}}));
                dispatch(profileGet({params: {companyId: envs.profileId}}));
      		} else {
        		dispatch(individualMessagesGet({params: null}));
                dispatch(individualGet());
      		}
  	}, []);
	return (
		<KycLayout nav={{prev: envs.admin ? '/summary' : `/`, step: -1, prevName: "Back to KYC", navPage: NavItem.CLIENT_INBOX}}>
			<>
				<CommentCustom>
                    <MessagesListing 
                        type='message' 
                        side='client'
                        msgGet={isBusiness ? companyMessagesGet : individualMessagesGet} 
                        msgGetClear={isBusiness ? companyMessagesGetClear : individualMessagesGetClear} 
                        msgPostBusiness={companyMessagePost}
                        msgPostPrivate={individualMessagePost}
                        msgPostBusinessClear={companyMessagePostClear} 
                        msgPostPrivateClear={individualMessagePostClear} 
                        msgViewedPutBusiness={companyMessageViewedPut}
                        msgViewedPutPrivate={individualMessageViewedPut}
                        msgViewedPutBusinessClear={companyMessageViewedPutClear}
                        msgViewedPutPrivateClear={individualMessageViewedPutClear}
                        listing={isBusiness ? messages.companyMessages : messages.individualMessages}
                        wasViewedBusiness={messages.companyMessageRead}
                        wasViewedPrivate={messages.individualMessageRead}
                        msgElemSentBusiness={messages.companyMessageSent}
                        msgElemSentPrivate={messages.individualMessageSent}
                     />
				</CommentCustom>
			</>
		</KycLayout>
			 
	)
}

export default KycInbox
