/**
 override toString, because AntTable has problem with columns.title different as String. Error key is [object Object]
 If You want to avoid error, add a 'key' property to your object
 example:
 	<SomeComponent key={uuid} />,
*/
Object.prototype.toString = function () {
	if (typeof this === 'object') {
		//@ts-ignore
		const key = this.key
		if (key !== undefined && key !== null && key !== '') {
			return key
		}
	}
	return `[object ${this?.constructor?.name}]`
}

export {}
