import { createSlice } from "@reduxjs/toolkit";
import { Components } from "../../../api/kyc-backend/generated/client";
import { companyProfileBackGet } from "../asyncThunks/companyProfileBackGet";
import { CaseReducerType } from "../../store";

export type ProfileResponse = Components.Schemas.CompanyResponse;
export type CompanyUpdatedResponse = Components.Schemas.CompanyResponse;

type State = {
  profile: ProfileResponse;
}

const initialState: State = {
  profile: {
      companyId: "",
      description: {
        id: "",
        companyId: "",
        website: "",
        activitiesAndBusinessModel: "",
        geographicalArea: "",
        annualTurnoverEUR: "",
        estimatedAmountOfBTC: "",
        estimatedAmountOfEUR: "",
        banks: "",
        fundingOfBusinessDescription: "",
        fundingOfBusinessType: "",
        businessLicence: "",
        complianceProgram: "",
        numberOfEmployees: -1,
        createdAt: ""
      },
      profile: {
        businessRelation: {
            isActive: false
        },
        companyId: "",
        name: "",
        legalForm: "",
        registrationNumber: "",
        placeOfIncorporation: "",
        phone: "",
        fax: "",
        createdAt: ""
      }
  }
}

type CaseReducers<State> = {
  companyProfileBackGetClear: CaseReducerType<State, object>;
};

export const companySlice = createSlice<State, CaseReducers<State>>({
  name: "company",
  initialState,
  reducers: {
    companyProfileBackGetClear: (state, { payload }) => {
        state.profile = initialState.profile
    }
  },
  extraReducers: (builder) => {
    builder.addCase(companyProfileBackGet.fulfilled, (state, action) => {
        state.profile = action.payload;
    });
  },
});

export const { companyProfileBackGetClear } = companySlice.actions;
export default companySlice.reducer;
