import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { companyMessagesGet } from "../asyncThunks/companyMessagesGet";
import { companyMessageViewedPut } from "../asyncThunks/companyMessageViewedPut";
import { individualMessagesGet } from "../asyncThunks/individualMessagesGet";
import { individualMessageViewedPut } from "../asyncThunks/individualMessageViewedPut";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";
import { reduce } from "lodash";

type MessageResponse = Components.Schemas.CompanyInternalMessageEntry[];
type MessageReadResponse = Paths.CompanyInternalMessageViewed.Responses.$200;
type IndividualMessageResponse = Components.Schemas.IndividualInternalMessageEntry[];
type IndividualMessageReadResponse = Paths.IndividualInternalMessageViewed.Responses.$200;

type State = {
  companyMessages: MessageResponse,
  companyMessageRead: MessageReadResponse,
  individualMessages: IndividualMessageResponse,
  individualMessageRead: IndividualMessageReadResponse
};

const initialState: State = {
  companyMessages: [],
  companyMessageRead: -1,
  individualMessages: [],
  individualMessageRead: -1
};

type CaseReducers<State> = {
  companyMessageViewedPutClear: CaseReducerType<State, object>;
  individualMessageViewedPutClear: CaseReducerType<State, object>;
};

export const messagesSlice = createSlice<State, CaseReducers<State>>({
  name: "messages",
  initialState,
  reducers: {
    companyMessageViewedPutClear: (state, { payload }) => {
      state.companyMessageRead = initialState.companyMessageRead
    },
    individualMessageViewedPutClear: (state, { payload }) => {
      state.individualMessageRead = initialState.individualMessageRead
    },
  },
  extraReducers: (builder) => {
    builder.addCase(companyMessagesGet.fulfilled, (state, action) => {
      state.companyMessages = action.payload;
    });
    builder.addCase(individualMessagesGet.fulfilled, (state, action) => {
      state.individualMessages = action.payload;
    });
    builder.addCase(companyMessageViewedPut.fulfilled, (state, action) => {
      state.companyMessageRead = action.payload;
    });
    builder.addCase(individualMessageViewedPut.fulfilled, (state, action) => {
      state.individualMessageRead = action.payload;
    });
  },
});

export const { companyMessageViewedPutClear, individualMessageViewedPutClear } = messagesSlice.actions;
export default messagesSlice.reducer;
