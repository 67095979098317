import { ConfigProvider } from 'antd'
import en from 'antd/lib/locale/en_GB'
import { antdTheme } from 'styles/antdTheme'
import { GlobalStyles } from 'styles/GlobalStyles'
import App from './App';
import './styles/font/poppins.css'
import { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './store/store'
import { persistor as backPersistor, store as backStore } from './store/store'
import { ThemeProvider } from 'styled-components'
import 'utilities/overrideObjectToString'
import 'antd/dist/reset.css';
import axios from "axios";
import {initApis} from "./api/kyc/api";
import {initApisBackend} from "./api/kyc-backend/api";

export let ENV = {
	ENV: 'local',
	API_URL: '',
	KEYCLOAK_DOMAIN: '',
	DOMAIN: '',
	KEYCLOAK_CLIENT_FRONT: '',
	KEYCLOAK_CLIENT_BACK:'',
	KEYCLOAK_REALM_FRONT:'',
	KEYCLOAK_REALM_BACK:''
}

// Get environment variables
const getEnv = () => {
	return axios.get('/config.json').then(({data}) => {
		ENV = {
			...data
		}

		return Promise.resolve()
	})
}

const initUi = () => {
	getEnv()
	.then(() => Promise.all([initApis(), initApisBackend()]))
	.then(() => {
		ReactDOM.render(
				<StrictMode>
					<Provider store={process.env.REACT_APP_KYC === 'admin' ? backStore : store}>
						<PersistGate
								persistor={process.env.REACT_APP_KYC === 'admin' ? backPersistor : persistor}
								loading={<></>}>
							<ThemeProvider theme={{}}>
								<ConfigProvider locale={en} theme={antdTheme}>
									<GlobalStyles/>

									<App/>
								</ConfigProvider>
							</ThemeProvider>
						</PersistGate>
					</Provider>
				</StrictMode>,
				document.getElementById('root'),
		)
	})
}

window.addEventListener('DOMContentLoaded', () => {
	initUi(); // no need to return in the event
});

