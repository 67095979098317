import { FC } from 'react'
import Well from './ui-elements/Well'

const KycNextSteps: FC = (props) => {
    return (
        <Well className='next-steps'>
            <p><b>Next Steps:</b></p>
            <p>1) Our compliance team will review the information and documents provided by you and give you feedback</p>
              <p>2) We may request additional information or documents in case anything is incomplete or unclear</p>
            <p>3) Once everything is complete, we will ask you to print, sign and send the original form including supporting documents to us by post/courier.</p>
        </Well>
)}

export default KycNextSteps