import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "store/store"
import { AsyncThunkDataType } from "store/common"
import { Paths } from "api/kyc/generated/client2"

type PathParameters = Paths.DeleteClient.PathParameters
type ProfilesResponse = Paths.DeleteClient.Responses.$204

export const clientDelete: AsyncThunkActionType<
  ProfilesResponse,
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("clientDelete", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI
  try {
    const response = await api2.deleteClient(data?.params, null, data?.config)
    return response.data as ProfilesResponse
  } catch (err: any) {
    return rejectWithValue(err.response.data)
  }
})
