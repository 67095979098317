import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "api/kyc/generated/client2";
import { api2 } from "api/kyc/api";

type RequestBody = Components.Schemas.ProfileComments;

type ProfileResponse = Paths.SetProfileComments.Responses.$204

type PathParameters = Paths.SetProfileComments.PathParameters;


export const businessProfileUpdate: AsyncThunkActionType<
  ProfileResponse,
  AsyncThunkDataType<PathParameters, RequestBody, "params">
> = createAsyncThunk(
  "businessProfileUpdate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api2.setProfileComments(params, data, config);
      return response.data as ProfileResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
