import Title from 'antd/es/typography/Title'
import * as Yup from 'yup'
import { ExclamationCircleOutlined, CloseCircleOutlined, CheckCircleOutlined, CheckOutlined, QuestionCircleOutlined, CaretLeftOutlined, CommentOutlined } from '@ant-design/icons'
import { moment } from '../../../kyc-front/ui-elements/FormDate'
import Text, { TextProps } from 'antd/es/typography/Text'
import { Row, Col, Divider, Space, Table, Badge, Popconfirm, message, Tooltip, Drawer, Form, Card } from 'antd'
import { useActionStatus } from '../../../../hooks/useActionStatus'
import { store, dispatch } from '../../../../store/store'
import { useSelector } from '../../../../hooks/useSelector'
import React, { FC, useEffect, useState, useRef, forwardRef } from 'react'
import { companyProfileBackGet } from '../../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { individualProfileBackGet } from '../../../../store/kyc-backend/asyncThunks/individualProfileBackGet'
import { riskProfileCriteriaGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileCriteriaGet'
import { riskProfileCompanyGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanyGet'
import { riskProfileIndividualGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualGet'
import { riskProfileCompanyEvaluationGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanyEvaluationGet'
import { riskProfileIndividualEvaluationGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualEvaluationGet'
import { riskProfileCompanySnapshotsGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanySnapshotsGet'
import { riskProfileIndividualSnapshotsGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualSnapshotsGet'
import { riskProfileCompanySnapshotCreate } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanySnapshotCreate'
import { riskProfileIndividualSnapshotCreate } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualSnapshotCreate'
import { riskProfileCompanySnapshotUpdate } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanySnapshotUpdate'
import { riskProfileIndividualSnapshotUpdate } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualSnapshotUpdate'
import { riskProfileCompanyApproveUpdate } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanyApproveUpdate'
import { riskProfileIndividualApproveUpdate } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualApproveUpdate'
import { riskProfileCompanyDraftGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanyDraftGet'
import { riskProfileIndividualDraftGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualDraftGet'
import { 
	riskProfileCompanySnapshotsGetClear,
	riskProfileIndividualSnapshotsGetClear,
	riskProfileIndividualSnapshotCreateClear, 
	riskProfileCompanySnapshotCreateClear, 
	riskProfileIndividualSnapshotUpdateClear, 
	riskProfileCompanySnapshotUpdateClear, 
	riskProfileIndividualGetClear, 
	riskProfileCompanyGetClear,
	riskProfileIndividualEvaluationGetClear,
	riskProfileCompanyEvaluationGetClear,
	riskProfileCompanyApproveUpdateClear,
	riskProfileIndividualApproveUpdateClear,
	riskProfileCompanyDraftGetClear,
	riskProfileIndividualDraftGetClear
} from '../../../../store/kyc-backend/slices/riskSlice'
import ApprovalSignature from '../../ui-elements/ApprovalSignature'
import { ButtonUpper } from '../../../kyc-front/ui-elements/Buttons'
import { FormInputStandalone } from '../../../kyc-front/ui-elements/FormInput'
import { FormCheckboxRadioFormStandalone } from '../../../kyc-front/ui-elements/FormCheckboxRadio'
import { SuccessInfo, SuccessIcon } from '../../../kyc-front/ui-elements/SuccessInfo'
import BasicFormWrapper from '../../../kyc-front/ui-elements/BasicForm'
import { DividerFirst } from '../../../kyc-front/ui-elements/Dividers'
import { camelCaseToName } from '../../../../utilities/nameParsers'
import { themeColor } from '../../../../styles/themeStyles'
import RiskProfileHistoryTable from './RiskProfileHistoryTable'
import { setFormUpdated } from '../../../../store/kyc-front/slices/navSlice'
import styled from 'styled-components'
import KycLayout from '../../../kyc-front/kyc-layout/KycLayout'

const CenteredCol = styled(Col)`
	text-align: center;
	margin-bottom: 0;
	margin-top: 40px;
`

const response = {
  "evaluationResults": [
    {
      "criterion": "string",
      "criteriaMet": 'false',
      "comments": [
        "string"
      ],
      "issues": [
      	"string"
      ],
      "criteriaMetOverride": 'false'
    }
  ],
  "totalScore": 0,
  "dueDiligenceScore": {},
  "calculatedAt": "string"
}

const TableCustom = styled(Table)`
	.criteriumMet {
		background-color: ${themeColor.grayOpaque}
	}
`

interface RProps {
    initialData: any,
    isEditable: boolean,
    handleSave: any,
    iterativeData: any,
    snapStatus: any,
    approvals: any,
    snapshotId: string,
    evaluationResults: any,
    submittingComment: any,
    handleRadioChange: any,
    handleInputChange: any,
    printRender: boolean
}

type SnapStateType = {
	isApproved: boolean,
	isDraft: boolean,
	isLatestApproved: boolean,
	isEditable: boolean
}

type ApprovalsType = {
	clientAdvisorApprovedBy?: string,
	clientAdvisorApprovedAt?: string,
	complianceOfficeApprovedBy?: string,
	complianceOfficeApprovedAt?: string
}

interface AProps {
    title: string,
    type: string,
    snapshotId: string,
    disabled?: boolean,
    value?: string,
    time?: string,
    isEditable: boolean
}

const RiskProfileForm: FC<RProps> = ({initialData, isEditable, handleSave, iterativeData, snapStatus, approvals, snapshotId, evaluationResults, submittingComment, handleRadioChange, handleInputChange, printRender, children}) => {
	const envs = useSelector((state) => state.general.envs);
	const isBusiness = envs.accountType === 'BUSINESS';
	const adminProfile = useSelector((state) => state.admin.admin);
    const columns = [
	    {
	        title: 'Risk criteria',
	        dataIndex: 'criterion',
	        key: 'criterion',
	        render: (criterion: any) => <><span>{printRender ? criterion.description : criterion.name === 'ContributorIsPEP' ? 'Contributor is PEP' : criterion.name === 'SpecificLicence2Operate' ? 'Has Specific License to Operate' : criterion.name === 'NoPersonalContact2Client' ? 'There Was No Personal Contact With Client' : camelCaseToName(criterion.name)}</span> <Tooltip title={criterion.description}><QuestionCircleOutlined style={{color: themeColor.gray}} /></Tooltip></>,
	    },
	    {
	        title: 'Result',
	        dataIndex: 'criteriaMet',
	        key: 'criteriaMet',
	        render: (criteriaMet: any) => <span>{criteriaMet.valueOverride !== null ? (criteriaMet.valueOverride === 'true' ? 'YES' : 'NO') : (criteriaMet.value === 'true' ? 'YES' : 'NO')} {criteriaMet.issues?.length ? <Tooltip title={criteriaMet.issues.join(', ')}><ExclamationCircleOutlined style={{color: themeColor.orangeWarning}} /></Tooltip>:<></>}</span>,
	    },
	    {
	        title: 'Available points',
	        dataIndex: 'points',
	        key: 'points',
	        width: printRender ? 120 : 'auto'
	    },
	    {
	        title: 'Client score',
	        dataIndex: 'score',
	        key: 'score',
	       	width: printRender ? 100 : 'auto',
	        render: (score: number) => <span>{score < 0 ? <b style={{color: themeColor.green }}>{score}</b> : score !== 0 ? <b style={{color: themeColor.orangeWarning }}>{score}</b> : <>{score}</>}</span>,
	    },
	    {
	        title: 'Comments',
	        dataIndex: 'comments',
	        key: 'comments',
	        className: 'commentsColumn',
	        width: printRender ? 0 : 'auto',
	        render: (comments: any) => <span>{comments?.length ? <span>{comments.length} <Tooltip title={'Newest comment: ' + comments[0].comment}><CommentOutlined style={{color: themeColor.blue}} /></Tooltip></span>:<></>}</span>,
	    },
	    Table.EXPAND_COLUMN
    ];

	return (
		<>
			<Form className='ant-form-vertical' id='riskProfileFormInnner'>
				{initialData.evaluationResults &&
					<TableCustom
						style={{marginBottom: 30}}
                		columns={columns}
                		pagination={false}
                		rowClassName={(record, index) => {
			                //@ts-ignore
			                if (record.criteriaMet.valueOverride === 'true' || record.criteriaMet.valueOverride === null && record.criteriaMet.value === 'true') {
			                  return "criteriumMet";
			                } else {
			                    return ""
			                }
			            }}
                		expandable={{
			                expandedRowRender: (criterium, i) => {
			                	return (
			                    <Row justify="space-between" style={{padding: '10px 0'}} key={'criterium' + i}>
			                        <Col span={24}>
			                        	{/*<FormInput showApprove={false} label='Points' name={'evaluationResults[' + i + '].criterion.points'}  error={errors?.evaluationResults ? errors.evaluationResults[i]?.criterion.points : ''} onChange={handleInputChange} disabled={false} printRender={false} value={values.evaluationResults[i].criterion.points} type='company' />*/}
			                            {isEditable &&
				                            <Row gutter={30}>
							                	<Col span={24}>
							                		
									                <label style={{marginBottom: 10, display: 'block'}}>Risk criterium met:</label>
									               	{/*@ts-ignore*/}
									                <FormCheckboxRadioFormStandalone showApprove={false} label='Purpose of business relationship' id={"criteriaMetOverride" + i} type='radio' value={evaluationResults[i].criteriaMetOverride !== null && evaluationResults[i].criteriaMetOverride !== undefined ? evaluationResults[i].criteriaMetOverride === 'true' ? 'true' : 'false' : evaluationResults[i].criteriaMet === 'true' ? 'true' : evaluationResults[i].criteriaMet  === 'n/a' ? 'false' : 'false'} onChange={(e) => handleRadioChange(e,i)}
										                options={[{
										                        label: 'YES',
										                        value: 'true'
										                    },
										                    {
										                        label: 'NO',
										                        value: 'false'
										                    }
										                ]
										            } disabled={false} />
									            </Col>
								            </Row>
								        }
							            <br />
							            <Row gutter={30}>
						                	<Col span={24}>
						                		{/*@ts-ignore*/}
						                		{criterium.comments.length ? <p>Comments:</p> : <p>No comments</p>}
						                		{/*@ts-ignore*/}
				                            	{criterium.comments.map((comment: any, j: number) => {
				                            		let name = adminProfile.profiles.find((elem) => elem.adminId === comment.author);
				                            		return (
					                            		<div style={{backgroundColor: 'white', padding: 15, marginBottom: 15}}>
					                            			{/*@ts-ignore*/}
						                            		<p style={{color: themeColor.gray, marginBottom: 0}}><span style={{fontSize: 16, marginBottom: 0, color: 'black'}}>{comment.comment}</span> - <span>{name.fullName ? camelCaseToName(name.fullName) : name.username ? camelCaseToName(name.username) : 'Admin'} </span><span>on {<span>{moment(comment.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>}</span></p>
					                            		</div>
				                            		)
				                            	})}
				                            	{/*@ts-ignore*/}
				                            	{isEditable &&<FormInputStandalone label='New comment:' name='evaluationResults' onChange={(e) => handleInputChange(e,i)} disabled={false} value={submittingComment[i]} />}
			                        		</Col>
			                        	</Row>
			                        	<br />
			                        	<Row gutter={30}>
						                	<Col span={24}>
								            	{isEditable && <ButtonUpper btnType='primary' onClick={() => handleSave(i)}>Save criterium</ButtonUpper>}
			                        		</Col>
			                        	</Row>
			                        </Col>
			                    </Row>
			                )},
			                expandIcon: ({ expanded, onExpand, record }) => {
			                    const handleExpand = (record: object, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			                        onExpand(record, e);
			                    }
			                    return (
			                        <>
			                        {expanded ? (
			                            <ButtonUpper className='btn-sm' btnType='default' onClick={e => handleExpand(record, e)}>Close</ButtonUpper>
			                        ) : (
			                            <ButtonUpper className='btn-sm' btnType='default' onClick={e => handleExpand(record, e)}>{isEditable ? 'Edit' : 'Preview'}</ButtonUpper>
			                        )}
			                        </>
			                    )
			                }
			            }}
                		dataSource={iterativeData.evaluationResults.map((criterium: any, i: number) => {
                			let el = {
                    			key: i, 
                    			criterion: {description: criterium.criterion.description, name: criterium.criterion.name}, 
                    			criteriaMet: {value: criterium.criteriaMet ? criterium.criteriaMet.toString() : 'false', valueOverride: criterium.criteriaMetOverride !== undefined && criterium.criteriaMetOverride !== null ? criterium.criteriaMetOverride?.toString() : null, score: criterium.criterion.points, issues: criterium.issues}, 
                    			comments: criterium.comments,
                    			points: criterium.criterion.points, 
                    			score: criterium.criteriaMetOverride?.toString() === 'true' ? criterium.criterion.points : criterium.criteriaMetOverride === null && criterium.criteriaMet?.toString() === 'true' ? criterium.criterion.points :  0
                			}
                    		return el;
                		})}
            		/>
            	}
        	</Form>
        	<div style={{marginBottom: printRender ? 20 : 50, marginTop: printRender ? 10 : 50}}>
            	<Title level={3}>Risk Profile Approvals</Title>
            </div>
      		<Row>
      			<Col>
              		<ApprovalSignature 
              			approveUpdate={isBusiness ? riskProfileCompanyApproveUpdate : riskProfileIndividualApproveUpdate}
              			approveParams={isBusiness ? {companyId: envs.profileId, riskProfileSnapshotId: snapshotId} : {individualId: envs.profileId, riskProfileSnapshotId: snapshotId}}
              			isEditable={snapStatus.isEditable}
              			title="Client Advisor" 
              			type="ClientAdvisorApproval" 
              			value={approvals.clientAdvisorApprovedBy}
              			time={approvals.clientAdvisorApprovedAt}
              			snapshotId={snapshotId} 
              			disabled={approvals.complianceOfficeApprovedBy !== undefined && approvals.complianceOfficeApprovedBy == adminProfile.profile.adminId}
              		/>
			    </Col>
			    <Col>
				    <ApprovalSignature 
				    	approveUpdate={isBusiness ? riskProfileCompanyApproveUpdate : riskProfileIndividualApproveUpdate}
				    	approveParams={isBusiness ? {companyId: envs.profileId, riskProfileSnapshotId: snapshotId} : {individualId: envs.profileId, riskProfileSnapshotId: snapshotId}}
				    	isEditable={snapStatus.isEditable}
				    	title="Compliance Officer" 
				    	type="ComplianceOfficerApproval" 
				    	value={approvals.complianceOfficeApprovedBy}
				    	time={approvals.complianceOfficeApprovedAt}
				    	snapshotId={snapshotId}
				    	disabled={approvals.clientAdvisorApprovedBy !== undefined && approvals.clientAdvisorApprovedBy == adminProfile.profile.adminId}
				    />
			    </Col>
		    </Row>
		    {children}
		</>		    
			 
	)
}

export default RiskProfileForm
