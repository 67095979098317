import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "../../store"
import { AsyncThunkDataType } from "../../common"
import { Components, Paths } from "api/kyc/generated/client2"

type PathParameters = Paths.SendBackToClient.PathParameters
type RequestBody = Paths.SendBackToClient.RequestBody

type ProfileResponse = Paths.SendBackToClient.Responses.$200


export const kycApplicationSendBack: AsyncThunkActionType<
  ProfileResponse,
  AsyncThunkDataType<PathParameters, RequestBody, "params">
> = createAsyncThunk("kycApplicationSendBack", async ({params, data, config}, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    let response = await api2.sendBackToClient(params, data, config);
    return response.data as ProfileResponse;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});