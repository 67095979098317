import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type MessagesResponse = Components.Schemas.IndividualInternalMessageEntry;
type PathParameters = Paths.IndividualInternalMessages.PathParameters;

export const individualBackMessagesGet: AsyncThunkActionType<
  MessagesResponse[],
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("individualBackMessagesGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.IndividualInternalMessages(data.params, null, data?.config);
    return response.data as MessagesResponse[];
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
