import { createSlice } from "@reduxjs/toolkit"
import { kycApplicationApprovalsGet } from "store/kyc-backend/asyncThunks/kycApplicationApprovalsGet"
import { kycApplicationApprovalsUpdate } from "store/kyc-backend/asyncThunks/kycApplicationApprovalsUpdate"
import { kycApplicationFinalApprove } from "store/kyc-backend/asyncThunks/kycApplicationFinalApprove"
import { CaseReducerType } from "../../store"
import { kycApplicationSendBack } from "../asyncThunks/kycApplicationSendBack"
import { kycApplicationReject } from "../asyncThunks/kycApplicationReject"

type State = {
    kycApplicationApprovals: any
    approvalsUpdated: any
    multiApprove: boolean
    finalApprove: boolean
    sentBack: boolean
    kycRejected: boolean
}

const initialState: State = {
    kycApplicationApprovals: {},
    approvalsUpdated: {},
    multiApprove: false,
    finalApprove: false,
    sentBack: false,
    kycRejected: false
}


type CaseReducers<State> = {
    kycApplicationApprovalsGetClear: CaseReducerType<State, object>
    kycApplicationApprovalsUpdateClear: CaseReducerType<State, object>
    kycApplicationFinalApproveClear: CaseReducerType<State, object>
    kycApplicationSendBackClear: CaseReducerType<State, object>
    kycApplicationRejectClear: CaseReducerType<State, object>
}

export const backKycApplicationSlice =  createSlice<State, CaseReducers<State>>({
    name: "backKycApplication",
    initialState,
    reducers: {
        kycApplicationApprovalsGetClear: (state, { payload }) => {
            state.kycApplicationApprovals = initialState.kycApplicationApprovals
        },
        kycApplicationApprovalsUpdateClear: (state, { payload }) => {
            state.approvalsUpdated = initialState.approvalsUpdated
        },
        kycApplicationFinalApproveClear: (state, { payload }) => {
            state.finalApprove = initialState.finalApprove
        },
        kycApplicationSendBackClear: (state, { payload }) => {
            state.sentBack = initialState.sentBack
        },
        kycApplicationRejectClear: (state, { payload }) => {
            state.kycRejected = initialState.kycRejected
        }
    },
    extraReducers: (builder) => {
        builder.addCase(kycApplicationApprovalsGet.fulfilled, (state, action) => {
            state.kycApplicationApprovals = action.payload.data
            state.multiApprove = false
        })
        builder.addCase(kycApplicationApprovalsUpdate.fulfilled, (state, action) => {
            state.approvalsUpdated = action.meta.arg.data
            //@ts-ignore
            state.multiApprove = action.meta.arg.params.multiApprove
        })
        builder.addCase(kycApplicationFinalApprove.fulfilled, (state, action) => {
            state.finalApprove = true
        })
        builder.addCase(kycApplicationSendBack.fulfilled, (state, action) => {
            state.sentBack = true
        })
        builder.addCase(kycApplicationReject.fulfilled, (state, action) => {
            state.kycRejected = true
        })
        
    },
})

export const { kycApplicationApprovalsGetClear, kycApplicationApprovalsUpdateClear, kycApplicationFinalApproveClear, kycApplicationSendBackClear, kycApplicationRejectClear } = backKycApplicationSlice.actions
export default backKycApplicationSlice.reducer
