import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "store/store"
import { Components } from "api/kyc/generated/client2"

export const clientActionPointsGetName = 'clientsActionPointsGet'
export type ClientActionPoint = Components.Schemas.ActionPoint
type Response = ClientActionPoint[]

export const clientsActionPointsGet: AsyncThunkActionType<Response> = createAsyncThunk(clientActionPointsGetName, async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI
  try {
    const response = await api2.getActionPoints(null, null, data?.config)
    return response.data as Response
  } catch (err: any) {
    return rejectWithValue(err.response.data)
  }
})
