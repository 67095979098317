import { createAsyncThunk } from "@reduxjs/toolkit";
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client2";

type PathParameters = Paths.DeleteSection1.PathParameters;
type Response = Paths.DeleteSection1.Responses.$200

export const kycApplicationSubSectionDelete: AsyncThunkActionType<
Response,
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk(
  "kycApplicationSubSectionDelete",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api2.deleteSection_1(params, data, config);
      return response.data as Response
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
