import { createSlice } from "@reduxjs/toolkit";
import { Components } from "../../../api/kyc/generated/client";
import { profileGet } from "../asyncThunks/profileGet";
import { ObjectKeyType } from "types";
import { reduce } from "lodash";
import { CaseReducerType } from "../../store";

type ProfileResponse = Components.Schemas.CompanyResponse;

type State = {
  userProfile: ProfileResponse;
};

const initialState: State = {
    userProfile: {
        companyId: "",
        profile: {
            businessRelation: {
                isActive: false
            },
            companyId: "",
            name: "",
            legalForm: "",
            registrationNumber: "",
            placeOfIncorporation: "",
            phone: "",
            fax: "",
            createdAt: "",
        }
    },
};

type CaseReducers<State> = {
  profileGetClear: CaseReducerType<State, object>;
};

export const profileSlice =  createSlice<State, CaseReducers<State>>({
  name: "profile",
  initialState,
  reducers: {
    profileGetClear: (state, { payload }) => {
        state.userProfile = initialState.userProfile
    },
  },
  extraReducers: (builder) => {
    builder.addCase(profileGet.fulfilled, (state, action) => {
      const { companyId, profile, description } = action.payload;
      state.userProfile = {
        companyId,
        profile,
        description
      };
    });
  },
});

export const { profileGetClear } = profileSlice.actions;
export default profileSlice.reducer;
