import { Table, Space, Empty } from 'antd'
import React, { useEffect, useState, FC } from 'react'
import translateCountries from 'utilities/translateCountries';

interface DataType {
    key: React.Key;
    name: string;
    dateOfBirth: string;
    nationality: string;
    residenceCountry: string;
    isCreating?: boolean;
}

interface CProps {
    list?: any[],
    name: string
}


const Directors: FC<CProps> = ({list, name}) => {
    const [dataSource, setDataSource] = useState<DataType[]>([]);
    const [initialized, setInitialized] = useState(false);


  const defaultColumns: ({ title: string, width?: string, editable?: boolean; dataIndex: string, select?: boolean, placeholder?: string, datepicker?: boolean })[] = [
    {
        title: 'Name',
        dataIndex: 'name',
        width: '30%',
        editable: true,
    },
    {
        title: 'Date of birth',
        dataIndex: 'dateOfBirth',
        editable: true,
        datepicker: true
    },
    {
        title: 'Nationality',
        dataIndex: 'nationality',
        placeholder: 'Select country',
        editable: true,
        select: true
    },
    {
        title: 'Residence country',
        dataIndex: 'residenceCountry',
        placeholder: 'Select country',
        editable: true,
        select: true
    }
  ];
    useEffect(() => {
        if(list && !initialized) {
            let dataSourceList: any[] = [];
            let countCopy = 0;
            list.forEach((elem, i) => {
                let nationalityValues = translateCountries(elem.nationality)
                dataSourceList.push({
                    key: countCopy,
                    name: elem.name,
                    dateOfBirth: elem.dob,
                    nationality: nationalityValues,
                    residenceCountry: translateCountries(elem.country)
                });
                countCopy = countCopy + 1
            })
            setDataSource([...dataSourceList]);
            setInitialized(true);
        }
    }, [list]);
  return (
    <Space direction='vertical' size={25}>
        {dataSource?.length ? (
            <Table
                className='directors-table'
                pagination={false}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource.map((elem, i) => {
                    return {
                        key: i,
                        name: elem.name,
                        dateOfBirth: elem.dateOfBirth,
                        nationality: elem.nationality,
                        residenceCountry: elem.residenceCountry
                    }
                })}
                columns={defaultColumns}
            />
        ) : (
            <Empty description={`No ${name}`} />
        )}
    </Space>
  );
};

export default Directors;
