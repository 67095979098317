import { createSlice } from '@reduxjs/toolkit'
import { setStepsScheme } from 'store/kyc-front/slices/kycApplicationSlice'
import { CaseReducerType } from 'store/store'
import { APSteps, UboSteps, Step } from 'stepsScheme'
import { kycApplicationApprovalsGet } from '../asyncThunks/kycApplicationApprovalsGet'

interface ValidationError {
    isApprovalRequirementSatisfied: boolean
    approvedBy: string[]
    ref: string
    slug: string
}
interface DocValidationError {
    approvals: [{ref: string, slug: string, isApprovalRequirementSatisfied: boolean, approvedBy: string[]}],
    id: string
}

interface StepErrors {
	step: number
    substep?: number
    link: string
    name: string
	validationErrors: ValidationError[]
    docErrors: DocValidationError[]
    refId?: string
}

const parseApprovalErrors = (steps: any, elem: any) => {
    let payloadEl: StepErrors[] = []
    let data = elem?.payload?.data
    steps?.forEach((step: any) => {
        const stepFields = step.type === 'authorizedPersons' ? APSteps.flatMap(step => step.fields) : step.type === 'beneficialOwners' ? UboSteps.flatMap(step => step.fields) : step.fields || []
        const personsRefList = step.ref === 'ap' ? data?.authorizedPersons : step.ref === 'ubo' ? data?.beneficialOwners : data
        const refList = step.refId && personsRefList?.find((el: any) => el.id === step.refId)
        const validationErrosList = step.refId ? refList?.approvals : step.type && data ? data[step.type] : data.approvals
        const validationDocsList = step.refId ? refList?.documents : data.documents
        let errorsByStep: ValidationError[] = validationErrosList?.filter((error: ValidationError) => {
            if(error.isApprovalRequirementSatisfied) {
                return false
            }
            if (error.ref !== '') {
                return stepFields.includes(error.ref + '.' + error.slug)
            } else {
                return stepFields.includes(error.slug)
            }
        }) || []
        let docErrorsByStep: DocValidationError[] = validationDocsList?.filter((error: DocValidationError) => {
            if(error.approvals[0].isApprovalRequirementSatisfied) {
                return false
            } 
            if (error.approvals[0].ref !== '') {
                return stepFields.includes(error.approvals[0].ref + '.' + error.approvals[0].slug)
            } else {
                return stepFields.includes(error.approvals[0].slug)
            }
        }) || []
        payloadEl.push({
            step: step?.step,
            substep: step?.substep,
            link: step.link,
            name: step.name,
            validationErrors: errorsByStep,
            docErrors: docErrorsByStep,
            refId: step.refId,
        })
        
    })
    return payloadEl
}

type State = {
    steps: Step[]
    approvalErrors: any[]
}

type CaseReducers<State> = {
    setStepsClear: CaseReducerType<State, Step[]>;
}

const initialState: State = {
    steps: [],
    approvalErrors: []
}

export const kycApprovalErrorsSlice =  createSlice<State, CaseReducers<State>>({
  name: 'kycApprovalErrors',
  initialState,
  reducers: {
    setStepsClear: (state) => {
      state.steps = initialState.steps
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setStepsScheme, (state, action) => {
        state.steps = action.payload;
    });
    builder.addCase(kycApplicationApprovalsGet.fulfilled, (state, action) => {
        state.approvalErrors = parseApprovalErrors(state.steps, action)
    })
  },
});

export const { setStepsClear } = kycApprovalErrorsSlice.actions;
export default kycApprovalErrorsSlice.reducer;
