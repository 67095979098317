export interface Step {
    step: number;
    name: string;
    link: string;
    ref?: string;
    refId?: string;
    fields: string[];
    substep?: number;
    hasSubsteps?: boolean;
    steps?: Step[];
    type?: string;
}

export const IndividualSteps: Step[] = [
    {
        step: 1,
        name: 'Personal details',
        link: '/step1',
        fields: ['firstName', 'lastName', 'dateOfBirth', 'placeOfBirth', 'nationality', 'occupation'],
    },
    {
        step: 2,
        name: 'Contact',
        link: '/step2',
        fields: ['residentialAddress.address', 'residentialAddress.postcode', 'residentialAddress.city', 'residentialAddress.country', 'email', 'phone', 'docs.ProofOfAddress']
    },
    {
        step: 3,
        name: 'Identification',
        link: '/step3',
        fields: ['identityDocument.number', 'identityDocument.placeOfIssue', 'identityDocument.dateOfIssue', 'identityDocument.dateOfExpiry', 'identityDocument.nationality', 'nationality', 'identityDocument.kind', 'docs.PassportFront', 'docs.IDCardFront', 'docs.IDCardBack']
    },
    {
        step: 4,
        name: 'Source of wealth',
        link: '/step4',
        fields: ['sourceOfWealthBusinessActivities', 'sourceOfWealthProfessionalOccupation', 'sourceOfWealthInheritance', 'sourceOfWealthOther', 'sourceOfWealthDescription', 'estimatedNetWorth', 'estimatedAmountOfEUR']
    },
    {
        step: 5,
        name: 'Declarations',
        link: '/step5',
        fields: ['isUSNationality', 'isPoliticallyExposedPerson']
    },
    {
        step: 6,
        name: 'Submit',
        link: '/step6',
        fields: ['correctnessDeclaration']
    }
]

export const CompanySteps: Step[] = [
    {
        step: 1,
        name: 'Company details',
        link: '/step1',
        fields: ['name', 'legalForm', 'dateOfIncorporation', 'registrationNumber', 'placeOfIncorporation', 'website']
    },
    {
        step: 2,
        name: 'Registration address',
        link: '/step2',
        fields: ['registeredAddress.address', 'registeredAddress.postcode', 'registeredAddress.city', 'registeredAddress.country', 'headOfficeAddress.sameAsRegistered', 'headOfficeAddress.headOfficeAddress', 'headOfficeAddress.address', 'headOfficeAddress.postcode', 'headOfficeAddress.city', 'headOfficeAddress.country']
    },
    {
        step: 3,
        name: 'Operations and activities',
        link: '/step3',
        fields: ['geographicalArea', 'numberOfEmployees', 'activitiesAndBusinessModel', 'annualTurnoverEUR', 'businessLicence']
    },
    {
        step: 4,
        name: 'Financials',
        link: '/step4',
        fields: ['estimatedAmountOfEUR', 'fundingOfBusinessType', 'fundingOfBusinessDescription']
    },
    {
        step: 5,
        name: 'Authorized persons',
        type: 'authorizedPersons',
        link: '/step5',
        fields: []
    },
    {
        step: 6,
        name: 'Ultimate Beneficial Owners',
        type: 'beneficialOwners',
        link: '/step6',
        fields: []
    },
    {
        step: 7,
        name: 'Documents',
        link: '/step7',
        fields: ['docs.OtherDocumentKind']
    },
    {
        step: 8,
        name: 'Submit',
        link: '/step8',
        fields: ['isSignatory']
    }
]
export const APSteps: Step[] = [
    {
        step: 1,
        name: 'Personal details',
        link: '/substep1',
        fields: ['firstName', 'lastName', 'roleType', 'dateOfBirth', 'placeOfBirth', 'nationality', 'occupation'],
    },
    {
        step: 2,
        name: 'Contact',
        link: '/substep2',
        fields: ['residentialAddress.address', 'residentialAddress.postcode', 'residentialAddress.city', 'residentialAddress.country', 'email', 'phone', 'docs.ProofOfAddress']
    },
    {
        step: 3,
        name: 'Identification',
        link: '/substep3',
        fields: ['identityDocument.number', 'identityDocument.placeOfIssue', 'identityDocument.dateOfIssue', 'identityDocument.dateOfExpiry', 'identityDocument.nationality', 'identityDocument.kind', 'nationality', 'docs.PassportFront', 'docs.IDCardFront', 'docs.IDCardBack']
    },
    {
        step: 4,
        name: 'Other documents',
        link: '/substep4',
        fields: ['docs.OtherDocumentKind']
    },
]

export const UboSteps: Step[] = [
    {
        step: 1,
        name: 'Personal details',
        link: '/substep1',
        fields: ['firstName', 'lastName', 'dateOfBirth', 'placeOfBirth', 'nationality', 'occupation'],
    },
    {
        step: 2,
        name: 'Contact',
        link: '/substep2',
        fields: ['residentialAddress.address', 'residentialAddress.postcode', 'residentialAddress.city', 'residentialAddress.country', 'email', 'phone', 'docs.ProofOfAddress']
    },
    {
        step: 3,
        name: 'Identification',
        link: '/substep3',
        fields: ['identityDocument.number', 'identityDocument.placeOfIssue', 'identityDocument.dateOfIssue', 'identityDocument.dateOfExpiry', 'identityDocument.nationality', 'nationality', 'identityDocument.kind', 'docs.PassportFront', 'docs.IDCardFront', 'docs.IDCardBack']
    },
    {
        step: 4,
        name: 'Other documents',
        link: '/substep4',
        fields: ['docs.OtherDocumentKind']
    },
    {
        step: 5,
        name: 'Source of wealth',
        link: '/substep5',
        fields: ['roleType', 'sourceOfWealthBusinessActivities', 'sourceOfWealthProfessionalOccupation', 'sourceOfWealthInheritance', 'sourceOfWealthOther', 'sourceOfWealthDescription', 'estimatedNetWorth']
    },
    {
        step: 6,
        name: 'Declarations',
        link: '/substep6',
        fields: ['isUSNationality', 'isPoliticallyExposedPerson']
    }
]

