import { createGlobalStyle, css } from 'styled-components'
import { antdTheme } from './antdTheme'
import { themeColor, themeSize, themeWeight } from './themeStyles'

const body = css`
	body, html {
    font-size: ${themeSize.base};
    font-family: ${antdTheme.token?.fontFamily};
	}
  a {
    color: inherit;
    text-decoration: none;
  }
`
const table = css`
	.ant-table-wrapper .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
		text-align: inherit;
		font-weight: ${themeWeight.bold};
	}
`
const antd = css`
  .ant-layout-header {
    height: 65px;
    line-height: 65px;
    padding-inline: 0;
  }
  .ant-btn {
    font-weight: ${themeWeight.extraBold};
  }
  .ant-divider {
    border-width: 2px;
  }
  // move required '*' after label not before
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    left: 103%;
  }
  // vertical form
  .ant-form-vertical {
    .ant-form-item-label {
      display: block;
      padding: 0 0 8px;
    }
    .ant-form-item {
      flex-direction: column;
    }
  }
  // hide arrow for submenu on small screen (hidden by hamburger)
  .ant-menu-submenu{
    .ant-menu-submenu-arrow {
      display: none!important;
    }
  }
  .ant-popover {
    min-width: 300px;
    .ant-popconfirm-message {
      padding: 16px 0 20px;
      font-size: 14px;
      line-height: 29px;
      .ant-popconfirm-message-icon .anticon {
        color: ${themeColor.primaryColor};
        font-size: 24px;
        top: 17px;
      }
    }
    .ant-popconfirm-message-title {
      padding-left: 32px;
      text-transform: initial;
    }
    .ant-popconfirm-buttons {
      display: flex;
      justify-content: right;
      button {
      padding: 5px 15px;
      height: auto;
        order: 1;
        &.ant-btn-primary {
          order: 0;
        }
      }
    }
  }
  .ant-space{
    display: flex;
  }
  .ant-statistic {
    display: inline-block;
    font-size: inherit;
  }
  // Header with 'colSpan'
  .ant-table-thead > tr > th[colspan]:not([colspan='1']){
    // column header which is 'colSpan' change default align
    text-align: inherit;
    
    // 'colSpan' header doesn't have border. Copy paste from ant styles
    &, &:hover{
      &:not(:last-child):before{
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 1.6em;
        background-color: rgba(0, 0, 0, 0.06) !important;
        transform: translateY(-50%);
        transition: background-color 0.3s;
        content: '';
      }
    }
  }
  
  // filter list on table
  .ant-table-filter-dropdown {
    font-size: 12px;
    
    .ant-dropdown-menu-item {
      font-size: inherit;
    }
  }
  .ant-typography{
    color: inherit;
  }
`
const resetFont = css`
	*[class*='ant-spin'],
	*[class*='ant-space'],
	*[class*='ant-typography'] {
		font-family: inherit;
		font-size: inherit;
	}
`

export const GlobalStyles = createGlobalStyle`
  ${body};
  ${table};
  ${resetFont};
  ${antd}
`
