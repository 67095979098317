import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";
import { companyBackMessagesGet } from "../asyncThunks/companyBackMessagesGet";
import { individualBackMessagesGet } from "../asyncThunks/individualBackMessagesGet";
import { companyBackMessagePost } from "../asyncThunks/companyBackMessagePost";
import { individualBackMessagePost } from "../asyncThunks/individualBackMessagePost";
import { companyBackMessageViewedPut } from "../asyncThunks/companyBackMessageViewedPut";
import { individualBackMessageViewedPut } from "../asyncThunks/individualBackMessageViewedPut";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";
import { reduce } from "lodash";

type MessageResponse = Components.Schemas.CompanyInternalMessageEntry[];
type MessageSentResponse = Components.Schemas.CompanyInternalMessageEntry[];
type IndividualMessageSentResponse = Components.Schemas.IndividualInternalMessageEntry[];
type IndividualMessageResponse = Components.Schemas.IndividualInternalMessageEntry[];
type MessageReadResponse = Paths.CompanyInternalMessageViewed.Responses.$200;
type IndividualMessageReadResponse = Paths.IndividualInternalMessageViewed.Responses.$200;

type State = {
  companyMessages: MessageResponse,
  companyMessageRead: {id: string, read: MessageReadResponse},
  companyMessageSent: MessageSentResponse,
  individualMessages: IndividualMessageResponse,
  individualMessageRead: {id: string, read: IndividualMessageReadResponse},
  individualMessageSent: IndividualMessageSentResponse,
};

const initialState: State = {
  companyMessages: [],
  companyMessageRead: {id: '', read: -1},
  companyMessageSent: [],
  individualMessages: [],
  individualMessageRead: {id: '', read: -1},
  individualMessageSent: []
};

type CaseReducers<State> = {
  companyBackMessageViewedPutClear: CaseReducerType<State, object>;
  individualBackMessageViewedPutClear: CaseReducerType<State, object>;
  companyBackMessagePostClear: CaseReducerType<State, object>;
  individualBackMessagePostClear: CaseReducerType<State, object>;
  companyBackMessagesGetClear: CaseReducerType<State, object>;
  individualBackMessagesGetClear: CaseReducerType<State, object>;
};

export const messagesSlice = createSlice<State, CaseReducers<State>>({
  name: "messages",
  initialState,
  reducers: {
    companyBackMessageViewedPutClear: (state, { payload }) => {
      state.companyMessageRead = initialState.companyMessageRead
    },
    individualBackMessageViewedPutClear: (state, { payload }) => {
      state.individualMessageRead = initialState.individualMessageRead
    },
    companyBackMessagePostClear: (state, { payload }) => {
      state.companyMessageSent = initialState.companyMessageSent
    },
    individualBackMessagePostClear: (state, { payload }) => {
      state.individualMessageSent = initialState.individualMessageSent
    },
    companyBackMessagesGetClear: (state, { payload }) => {
      state.companyMessages = initialState.companyMessages
    },
    individualBackMessagesGetClear: (state, { payload }) => {
      state.individualMessages = initialState.individualMessages
    },
  },
  extraReducers: (builder) => {
    builder.addCase(companyBackMessagesGet.fulfilled, (state, action) => {
      state.companyMessages = action.payload;
    });
    builder.addCase(companyBackMessagePost.fulfilled, (state, action) => {
      state.companyMessageSent = action.payload;
    });
    builder.addCase(individualBackMessagesGet.fulfilled, (state, action) => {
      state.individualMessages = action.payload;
    });
    builder.addCase(individualBackMessagePost.fulfilled, (state, action) => {
      state.individualMessageSent = action.payload
    });
    builder.addCase(companyBackMessageViewedPut.fulfilled, (state, action) => {
      state.companyMessageRead = action.payload;
    });
    builder.addCase(individualBackMessageViewedPut.fulfilled, (state, action) => {
      state.individualMessageRead = action.payload;
    });
  },
});

export const { companyBackMessageViewedPutClear, individualBackMessageViewedPutClear, companyBackMessagePostClear, individualBackMessagePostClear, companyBackMessagesGetClear, individualBackMessagesGetClear } = messagesSlice.actions;
export default messagesSlice.reducer;
