import { createSlice } from "@reduxjs/toolkit";
import { Components } from "../../../api/kyc-backend/generated/client";
import { individualProfileBackGet } from "../asyncThunks/individualProfileBackGet";
import { CaseReducerType } from "../../store";

export type ProfileResponse = Components.Schemas.IndividualProfile
export type IndividualBasicResponse = Components.Schemas.IndividualProfile;

type State = {
  individual: ProfileResponse
};

const emptyIndividual = {
    individualId: '',
    businessRelation: {
        isActive: false
    },
    profile: {
      
      beneficialOwnerId: '',
      sourceOfWealthBusinessActivities: false,
      sourceOfWealthProfessionalOccupation: false,
      sourceOfWealthInheritance: false,
      sourceOfWealthOther: false,
      isPoliticallyExposedPerson: false,
      isUSNationality: false,
      createdAt: ''
    }, 
    createdAt: '', 
    reviewStatus: ''
}


const initialState: State = {
  individual: emptyIndividual
};

type CaseReducers<State> = {
  individualProfileBackGetClear: CaseReducerType<State, object>;
};

export const individualSlice = createSlice<State, CaseReducers<State>>({
  name: "individual",
  initialState,
  reducers: {
    individualProfileBackGetClear: (state, { payload }) => {
      state.individual = initialState.individual
    }
  },
  extraReducers: (builder) => {
    builder.addCase(individualProfileBackGet.fulfilled, (state, action) => {
      state.individual = action.payload
    });
  },
});

export const { individualProfileBackGetClear } = individualSlice.actions;
export default individualSlice.reducer;
