import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";
import { companyFxAccountCreate } from "../asyncThunks/companyFxAccountCreate";
import { individualFxAccountCreate } from "../asyncThunks/individualFxAccountCreate";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";

export type AccountResponse = Paths.CreateCompanyFxAccount.Responses.$200;

type State = {
  accountCompany: AccountResponse,
  accountIndividual: AccountResponse
};

const initialState: State = {
    accountCompany: '',
    accountIndividual: ''
};

type CaseReducers<State> = {
    companyFxAccountCreateClear: CaseReducerType<State, object>;
    individualFxAccountCreateClear: CaseReducerType<State, object>;
};

export const fxAccountSlice = createSlice<State, CaseReducers<State>>({
  name: "fxAccount",
  initialState,
  reducers: {
    companyFxAccountCreateClear: (state, { payload }) => {
        state.accountCompany = initialState.accountCompany
    },
    individualFxAccountCreateClear: (state, { payload }) => {
        state.accountIndividual = initialState.accountIndividual
    },
  },
  extraReducers: (builder) => {
    builder.addCase(companyFxAccountCreate.fulfilled, (state, action) => {
        state.accountCompany = action.payload
    });
    builder.addCase(individualFxAccountCreate.fulfilled, (state, action) => {
        state.accountIndividual = action.payload
    });
  },
});

export const { companyFxAccountCreateClear, individualFxAccountCreateClear }  = fxAccountSlice.actions;
export default fxAccountSlice.reducer;
