import { Spin } from 'antd'
//import Logo from 'assets/logo-kyc.png'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

const Box = styled.div<{ opacity: 0 | 1 }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #fff;
	z-index: 9999;
	display: flex;
	justify-content: center;
	padding: 20% 0 0 0;
	opacity: ${({ opacity }) => opacity};
	transition: all 0.3s ease;

	svg {
		font-size: 15em;
		margin-right: 20px;
	}
`
const Content = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-start;
`

interface IProps {
	visible?: boolean | 'time'
}
export const CoverPage: FC<IProps> = ({ visible = 'time' }) => {
	const [opacity, setOpacity] = useState<0 | 1>(0)
	const [show, setShow] = useState<boolean>(false)

	const toggle = (show: boolean) => {
		setOpacity(show ? 1 : 0)
		show ? setShow(true) : setTimeout(() => setShow(false), 250) // like transition
	}

	useEffect(() => {
		if (visible === true) {
			toggle(true)
		} else if (visible === false) {
			toggle(false)
		} else if (visible === 'time') {
			toggle(true)
			setTimeout(() => toggle(false), 800)
		}
	}, [visible])

	return show ? (
		<Box opacity={opacity}>
			<Content>
				{/*<Logo />*/}
				<Spin spinning />
			</Content>
		</Box>
	) : null
}