import { FC } from 'react'
import { AppRoutes } from 'routes/AppRoutes'
import { CoverPage } from 'modules/kyc-front/ui-elements/CoverPage'
import { useIsUserLogged } from 'modules/keycloak'

const App: FC = () => {
	const { isUserLogged } = useIsUserLogged()
	return (
		<>
			<CoverPage visible={isUserLogged ? 'time' : window.location.pathname === '/user/register' || window.location.pathname === '/403' ? 'time' : !isUserLogged} />
			<AppRoutes />
		</>
	)
}



export default App