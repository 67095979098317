import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { store } from "../../store";

type IndividualResponse = Paths.IndividualInternalMessageViewed.Responses.$200;
type PathParameters = Paths.IndividualInternalMessageViewed.PathParameters;

export const individualMessageViewedPut: AsyncThunkActionType<
  IndividualResponse,
  AsyncThunkDataType<PathParameters, null, "data">
> = createAsyncThunk(
  "individualMessageViewedPut",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    if (!params) {
      return rejectWithValue("Missing path parameters");
    }
    try {
      const response = await api.IndividualInternalMessageViewed(params, data, config);

      return response.data as IndividualResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
