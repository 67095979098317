import { UserOutlined, FileProtectOutlined, ReadOutlined, SolutionOutlined } from '@ant-design/icons'
import { Tag, Row, Col } from 'antd'
import Text from 'antd/es/typography/Text'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { themeColor, themeSize } from '../../styles/themeStyles'
import { useSelector } from '../../hooks/useSelector'

const CustomList = styled.ul`
    list-style: none;
    padding-left: 0;
    li {
        margin-bottom: 30px;
    }
    .anticon {
        margin-left: 6px;
    }
`
const CustomTag = styled(Tag)`
    border-radius: 100%;
    width: 72px;
    height: 72px;
    font-size: ${themeSize.heading3};
    color: ${themeColor.blue};
    line-height: 72px;
    text-align: center;
`

const CustomText = styled(Text)`
    font-size: ${themeSize.base};
    font-weight: bold;
    margin-top: 22px;
    display: block;
    margin-left: 5px;

`

interface IProps {
    icon: ReactNode,
    text: string
}

const DocumentsStep: FC <IProps>= ({
    icon, text
}) => {
  return (
        <li>  
            <Row>
                <Col md={6} lg={3}>
                    <CustomTag icon={icon} color={themeColor.blueLight} />
                </Col>
                <Col md={16} lg={21}>
                    <CustomText>{text}</CustomText>
                </Col>
            </Row>
        </li>
    )
}

export const KycDocumentsSteps: FC = () => {
    const envs = useSelector((state) => state.general.envs);
    const { accountType } = envs;
    return ( 
        <>
            {accountType === 'BUSINESS' ? (
                <CustomList>
                    <DocumentsStep icon={<FileProtectOutlined />} text="1. Recent original extract from the corporate register, certificate of incumbency or similar document, not older than 12 months. This document must state the registered office of the company, its shareholders and directors."  />
                    <DocumentsStep icon={<ReadOutlined />} text="2. Latest audited financial statements"  />
                    <DocumentsStep icon={<SolutionOutlined />} text="3. Certified true copy of passport / identity card (European national identity cards only) for each director acting on behalf of the company towards us."  />
                    <DocumentsStep icon={<UserOutlined />} text="4. Simple copy of passport / identity card (European national identity cards only) of each beneficial owner owning or controlling more than 25% of the company."  />
                </CustomList>

            ) : (
                <CustomList>
                    <DocumentsStep icon={<SolutionOutlined />} text="1. Certified true copy of passport / identity card (European national identity cards only)."  />
                    <DocumentsStep icon={<FileProtectOutlined />} text="2. Proof of address - Simple copy of utility bill, bank account statement or other document containing Your address." /> 
                </CustomList>
            )}
        </>
    )
}