import { FC } from 'react'
import styled from 'styled-components'
import { Col, Card } from 'antd'
import { moment } from '../../kyc-front/ui-elements/FormDate'
import { ButtonUpper } from '../../kyc-front/ui-elements/Buttons'
import { useSelector } from '../../../hooks/useSelector'
import { dispatch } from '../../../store/store'
import { camelCaseToName } from '../../../utilities/nameParsers'

interface AProps {
    title: string,
    type?: string,
	role?: "CLIENT_ADVISOR" | "COMPLIANCE_OFFICER",
    snapshotId: string,
    disabled?: boolean,
    value?: string,
    time?: string,
    isEditable: boolean,
    approveUpdate: (arg0: any) => any,
    approveParams: {
    	companyId?: string,
    	individualId?: string,
    	businessProfileId?: string,
		businessProfileSnapshotId?: string,
    	riskProfileSnapshotId?: string
    }
}

const CardCustom = styled(Card)`
	width: 300px;
	margin-right: 30px;
	text-align: center;
	p {
		font-size: 20px;
		font-style: italic;
		margin-bottom: 0; 
		lineHeight: 2
	}
	.ant-card-body {
		padding-top: 20px;
    	padding-bottom: 20px;
    	height: 100px;
	}
`

const ApprovalSignature: FC<AProps> = ({title, value, time, disabled, type, role, isEditable, approveUpdate, approveParams}) => {
	const adminProfile = useSelector((state) => state.admin.admin)
	const handleApproveClick = () => {
		if(role) {
			dispatch(approveUpdate({params: approveParams, data: {role: role}}))
		} else {
			dispatch(approveUpdate({params: approveParams, data: {approve: {type: type}}}))
		}
	}
	const adminName = adminProfile?.profiles.find((el) => el.adminId === value)
	return (
		<Col className='approvalsSignature'>
	  		<CardCustom size='small' title={title}>
	  			{value ? (
	  				<>
			      		<p style={{marginBottom: 5}}>{camelCaseToName(adminName?.fullName ? adminName?.fullName : adminName?.username)}</p>
			      		<label style={{fontSize: 13}}>{moment(time).format('YYYY-MM-DD HH:mm:ss')}</label>
		      		</>
		      	) : (
		      		<div style={{marginTop: 10}}>
		      			<ButtonUpper btnType="primary" ghost disabled={!isEditable || disabled} onClick={handleApproveClick}>Approve</ButtonUpper>
		      		</div>
		      	)}
		    </CardCustom>
	    </Col>
	)
}

export default ApprovalSignature
