import { FC, ReactNode } from 'react'
import { Row, Col, Space } from 'antd'
import Text from 'antd/es/typography/Text'
import styled from 'styled-components'
import { useSelector } from 'hooks/useSelector'
import FormInput from 'modules/kyc-front/ui-elements/FormInput'
import { FormCheckboxRadio } from 'modules/kyc-front/ui-elements/FormCheckboxRadio'
import Well from 'modules/kyc-front/ui-elements/Well'
import ApproveInput from "modules/kyc-backend/ui-elements/Approve"
import { themeColor } from 'styles/themeStyles'
import { descriptions } from './common'

const CheckboxWrapperStyles = styled.div`
    label {
        margin-bottom: 15px;
        line-height: 20px;
        gap: 25px;
    }
    input {
        position: relative;
        top: 3px;
    }
`
const ErrorContainer = styled.div`
    color: ${themeColor.red};
`

interface CProps {
    error?: string,
    children: ReactNode
}

const CheckboxWrapper: FC<CProps> = ({error, children}) => {
    return (
        <CheckboxWrapperStyles className='checkbox-wrapper'>
            {children}
            {error && error !== '' &&
                <ErrorContainer>{error}</ErrorContainer>
            }
        </CheckboxWrapperStyles>
    )
}

interface KProps {
    values: any,
    errors: any,
    handleInputChange: any,
    disabled?: boolean,
    printRender?: boolean
}

const KycCompanyFinancialsForm: FC<KProps> = ({values, errors, handleInputChange, disabled, printRender}) => {
    const envs = useSelector((state) => state.general.envs);    
    return (
        <Space direction='vertical' size={'large'}>
            <label>{descriptions.estimatedExchangeAmountFuture}:</label>
            <Row gutter={30}>
                <Col span={13}>
                    <FormInput label='Amount (EUR) *' name='estimatedAmountOfEUR' error={errors.estimatedAmountOfEUR} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.estimatedAmountOfEUR} type='company' formatting='numbers' />
                </Col>
            </Row>
            <FormCheckboxRadio className={'checkboxCompanyAddress'} label='Funding of business activities' id='fundingOfBusinessType' error={errors.fundingOfBusinessType} type='radio' onChange={handleInputChange}
                options={[{
                        label: 'equity/debt contribution by UBO',
                        value: 'EquityDebtContributionByUBO'
                    },
                    {
                        label: 'other',
                        value: 'OtherFundingOfBusinessType'
                    }
                ]
            } disabled={disabled} approveType='company' />
            <Well className='well'>
                <FormInput label='Please explain' name='fundingOfBusinessDescription' error={errors.fundingOfBusinessDescription} id="fundingOfBusinessDescriptionTextarea" component='textarea' onChange={handleInputChange} disabled={disabled} value={values.fundingOfBusinessDescription} printRender={printRender} type='company' />
            </Well>
        </Space>
        )

}

export default KycCompanyFinancialsForm