/**
 * Download url as file
 */
export const downloadFile = (uri: string, name: string): void => {
	const link = document.createElement('a')
	link.download = name
	link.href = uri
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}
