import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "../../store"
import { AsyncThunkDataType } from "../../common"
import { Components, Paths } from "api/kyc/generated/client2"

type PathParameters = Paths.UploadAuthorizedPersonDocument.PathParameters & Paths.UploadAuthorizedPersonDocument.QueryParameters
type Data = Paths.UploadAuthorizedPersonDocument.RequestBody;
type Response = 200


export const kycApplicationSectionDocCreate: AsyncThunkActionType<
Response,
  AsyncThunkDataType<PathParameters, Data, "params">
> = createAsyncThunk("kycApplicationSectionDocCreate", async ({ params, data, config }, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    let response = await api2.uploadAuthorizedPersonDocument(params, data, config);
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err.response);
  }
});