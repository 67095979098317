import { FC, ReactNode, useState } from 'react'
import { Row, Col, Space, Drawer } from 'antd'
import Text from 'antd/es/typography/Text'
import styled from 'styled-components'
import { useSelector } from 'hooks/useSelector'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { FormCheckboxRadio } from 'modules/kyc-front/ui-elements/FormCheckboxRadio'
import ApproveInput from "modules/kyc-backend/ui-elements/Approve"
import { themeColor } from 'styles/themeStyles'
import { descriptions } from './common'

const CheckboxWrapperStyles = styled.div`
    label {
        margin-bottom: 15px;
        line-height: 20px;
        gap: 25px;
    }
    input {
        position: relative;
        top: 3px;
    }
`
const ErrorContainer = styled.div`
    color: ${themeColor.red};
`

interface CProps {
    error?: string,
    children: ReactNode
}

const CheckboxWrapper: FC<CProps> = ({error, children}) => {
    return (
        <CheckboxWrapperStyles className='checkbox-wrapper'>
            {children}
            {error && error !== '' &&
                <ErrorContainer>{error}</ErrorContainer>
            }
        </CheckboxWrapperStyles>
    )
}

interface KProps {
    errors: any,
    handleInputChange: any,
    setFieldValue: any,
    disabled?: boolean,
    uboType?: boolean,
    type: 'individual' | 'authorized-persons' | 'ubos'
}

const KycDeclarationsForm: FC<KProps> = ({errors, handleInputChange, setFieldValue, disabled, uboType, type}) => {
    const envs = useSelector((state) => state.general.envs);
    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    const handleCheckChange = (el: any) => {
        setFieldValue(el.target.name, JSON.parse(el.target.value.toLowerCase()))
        handleInputChange();
    }
    return (
        <Space direction='vertical' size={'large'}>
            <div>
                <Row justify="space-between">
                    <Col>
                        <Text><b>1. Politically Exposed Person</b></Text><QuestionCircleOutlined style={{'marginLeft': '5px'}} onClick={showDrawer} />
                    </Col>
                    <Col>
                        {envs.admin &&
                            <ApproveInput name='isPoliticallyExposedPerson' type={type} />
                        }
                    </Col>
                </Row>
                <Text>Please tick <b>ONE</b> of below options.</Text>
            </div>
            <div>
                <CheckboxWrapper>
                    <FormCheckboxRadio id='isPoliticallyExposedPerson' type='radio' onChange={handleCheckChange} error={errors.isPoliticallyExposedPerson}
                        options={[{
                                label: descriptions.nonPoliticallyExposedPerson + descriptions.politicallyExposedPersonExplanation,
                                value: 'false'
                            },
                            {
                                label: `${descriptions.politicallyExposedPerson}.`,
                                value: 'true'
                            }
                        ]
                    } disabled={disabled} showApprove={false} approveType={type} />
                    
                </CheckboxWrapper>
                <Drawer title="Politically Exposed Person" placement="right" onClose={onClose} open={visible}>
                    <p>Prominent public functions being:</p>
                    <p>a) Head of State or of government, ministers, deputy and secretaries of ministers, and high-ranked party officials; </p>
                    <p>b) Members of Parliament and similar legislative functions;</p>
                    <p>c) Members of supreme courts, constitutional courts and other high ranking courts, against which – except in extraordinary cases – there is no appeal;</p> 
                    <p>d) Auditors or directors of Central Banks;</p> 
                    <p>e) Ambassadors, consulates and high ranking officers of the armed forces;</p>
                    <p>f) Members of administrative, management or supervisory bodies of state owned enterprises;</p> 
                    <p>g) Directors, deputy directors and members of senior management and similar officials of international governmental organizations.</p>
                </Drawer>
            </div>
            <div>
                    <Row justify="space-between">
                        <Col>
                            <Text><b>2. US Person</b></Text>
                        </Col>
                        <Col>
                            {envs.admin &&
                                <ApproveInput name='isUSNationality' type={type} />
                            }
                        </Col>
                    </Row>
                    <Text>Please tick <b>ONE</b> of below options.</Text>
                </div>
            <CheckboxWrapper>
                <FormCheckboxRadio id='isUSNationality' type='radio' onChange={handleCheckChange} error={errors.isUSNationality}
                    options={[{
                            label: descriptions.nonUSNationality + descriptions.uSNationalityExplanation,
                            value: 'false'
                        },
                        {
                            label: `${descriptions.uSNationality}.`,
                            value: 'true'
                        }
                    ]
                } disabled={disabled} showApprove={false} approveType={type} />
            </CheckboxWrapper>
            {uboType &&
                <div>
                    <Text><b>3. Correctness and changes</b></Text><br/>
                  <Text>{descriptions.correctness1}<br />{descriptions.correctness2} <b>{descriptions.correctness3}</b></Text>
                </div>
            }
        </Space>
        )

}

export default KycDeclarationsForm