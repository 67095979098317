import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { IndividualDocResponse } from "../slices/approvedSlice";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type RequestBody = Components.Schemas.ApproveRequest;
type reqBody = {
  data: RequestBody,
  name: string
}
type PathParameters = Paths.ApproveIndividualDocument.PathParameters;
type approveResp = {
  name: string,
  data: IndividualDocResponse
}

export const individualDocumentApproveUpdate: AsyncThunkActionType<
  approveResp,
  AsyncThunkDataType<PathParameters, reqBody, "params">
> = createAsyncThunk(
  "individualDocumentApproveUpdate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.ApproveIndividualDocument(params, data?.data, config);
      return {name: data?.name, data: response.data} as approveResp;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
