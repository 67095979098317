import { AnyAction, createSlice } from '@reduxjs/toolkit'
import { CaseReducerType } from "../../store"

type Status = 'pending' | 'rejected' | 'fulfilled' | 'cleared'

type State = {
	[key: string]: {
		status: Status
	}
}

const initialState: State = {}

const actionName = (action: AnyAction) => action.type.replace(`/${action.meta.requestStatus}`, '')

type CaseReducers<State> = {
  resetResponseInfo: CaseReducerType<State, object>;
};

export const responseInfo = createSlice<State, {}>({
	name: 'responseInfo',
	initialState,
	reducers: {
		resetResponseInfo: (state: any, action: any) => {
			state[actionName(action)] = {
				...state[actionName(action)],
				status: action.meta.requestStatus,
			}
		},
	},
	extraReducers: (builder) => {
		builder
		.addMatcher(
			(action) => action.type.endsWith('/pending') || action.type.endsWith('/rejected') || action.type.endsWith('/fulfilled'),
			(state, action) => {
				state[actionName(action)] = {
					...state[actionName(action)],
					status: action.meta.requestStatus,
				}
			},
		)
		.addMatcher(
			(action) => action.type.endsWith('Clear'),
			(state, action) => {
				let name = action.type.substring(action.type.indexOf("/") + 1).replace('Clear', '');
				state[name] = {
					...state[name],
					status: 'cleared',
				}
			},
		)
	},
})

export default responseInfo.reducer
