import { createAsyncThunk } from '@reduxjs/toolkit'
import { AsyncThunkDataType } from 'store/common'
import { AsyncThunkActionType } from 'store/store'
import { getToken as getTokenRequest, IGetTokenParams } from '../api/keycloakActions'
import { SessionResponse } from '../api/types'

type IReturn = {
	data: SessionResponse
	params: IGetTokenParams
}

export const getToken: AsyncThunkActionType<IReturn, AsyncThunkDataType<IGetTokenParams, null, 'params'>> = createAsyncThunk(
	'keycloak/getToken',
	async ({ params }, thunkAPI) => {
		const { rejectWithValue } = thunkAPI

		try {
			const response = await getTokenRequest(params)

			return {
				data: response.data,
				params,
			} as IReturn
		} catch (err: any) {
			return rejectWithValue('')
		}
	},
)
