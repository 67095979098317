import apiSchema from "./generated/api.json"
import apiSchema2 from "./generated/api2.json"
import {Client} from "./generated/client"
import {setInterceptors} from "./interceptors"

import {OpenAPIClientAxios} from "openapi-client-axios"
import {Client2} from "./generated/client2";
import {ENV} from "../../index";

export let api: Client
export let api2: Client2

export const initApis = (): Promise<void> => {
  const openAPIClientAxios = new OpenAPIClientAxios({
    // @ts-ignore
    definition: apiSchema,
    strict: true,
    timeout: 20000,
    validate: true,
    // @ts-ignore
    withServer: {url: ENV.API_URL, description: "KYC API"},
    axiosConfigDefaults: {
      headers: {
        "Content-Type": "application/json",
      },
      /*adapter: throttleAdapterEnhancer(
        cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
          defaultCache: new Cache({ max: 100, maxAge: 1000 * 60 }),
        }),
        { threshold: 2 * 1000 }
      ),*/
    },
  });


  const openAPIClientAxios2 = new OpenAPIClientAxios({
    // @ts-ignore
    definition: apiSchema2,
    strict: true,
    timeout: 20000,
    validate: true,
    // @ts-ignore
    withServer: {url: ENV.API_URL, description: "KYC API"},
    axiosConfigDefaults: {
      headers: {
        "Content-Type": "application/json",
      },
      /*adapter: throttleAdapterEnhancer(
        cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
          defaultCache: new Cache({ max: 100, maxAge: 1000 * 60 }),
        }),
        { threshold: 2 * 1000 }
      ),*/
    },
  });

  return Promise.all([
    openAPIClientAxios.init<Client>(),
    openAPIClientAxios2.init<Client2>()])
  .then(clients => {
    const [client1, client2] = clients
    setInterceptors(client1)
    setInterceptors(client2)
    api = client1
    api2 = client2
    return Promise.resolve()
  })

}
