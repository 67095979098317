import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { ScoringType, SnapResponse } from "../slices/riskSlice";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type PathParameters = Paths.CreateIndividualRiskProfileScoreSnapshot.PathParameters;

type ReqBody = Components.Schemas.RiskEvaluationResult[];

export const riskProfileIndividualSnapshotCreate: AsyncThunkActionType<
  SnapResponse,
  AsyncThunkDataType<PathParameters, ReqBody, "params">
> = createAsyncThunk(
  "riskProfileIndividualSnapshotCreate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.CreateIndividualRiskProfileScoreSnapshot(params, data, config);
      return response.data as SnapResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
