import { FC } from 'react'
import { moment } from 'modules/kyc-front/ui-elements/FormDate'
import _ from 'lodash';
import { Row, Col, Space } from 'antd'
import FormInput from 'modules/kyc-front/ui-elements/FormInput'
import FormDate from 'modules/kyc-front/ui-elements/FormDate'

interface KProps {
    values: any,
    errors: any,
    handleInputChange: any,
    onDateChange: any,
    printRender?: boolean,
    disabled?: boolean
}


const KycCompanyDetailsForm: FC<KProps> = ({values, errors, handleInputChange, onDateChange, disabled, printRender }) => {

    return (
        <Space direction='vertical' size={'large'}>
            <FormInput label='Name of legal entity *' name='name' error={errors.name} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.name} type='company'  />
            <FormInput label='Legal form *' name='legalForm' error={errors.legalForm} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.legalForm} type='company' />
            <Row gutter={30}>
                <Col span={12}>
                    <FormDate label='Date of incorporation *' id='dateOfIncorporation' error={errors.dateOfIncorporation} defaultValue={values.dateOfIncorporation ? moment(values.dateOfIncorporation, 'YYYY-MM-DD') : undefined} onChange={(val) => onDateChange(val, 'dateOfIncorporation')} disabled={disabled}  type='company' />
                </Col>
                <Col span={12}>
                    <FormInput label='Registration number *' name='registrationNumber' error={errors.registrationNumber} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.registrationNumber} type='company' />
                </Col>
            </Row>
            <Row gutter={30}>
                <Col span={24}>
                    <FormInput label='Place of incorporation *' name='placeOfIncorporation' error={errors.placeOfIncorporation} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.placeOfIncorporation} type='company' />
                </Col>
            </Row>
            <FormInput label='Corporate website (optional)' name='website' error={errors.website} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.website} type='company' />
        </Space>
    )
	
}

export default KycCompanyDetailsForm
