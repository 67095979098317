import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";
import { riskProfileCriteriaGet } from "../asyncThunks/riskProfileCriteriaGet";
import { riskProfileCompanyGet } from "../asyncThunks/riskProfileCompanyGet";
import { riskProfileIndividualGet } from "../asyncThunks/riskProfileIndividualGet";
import { riskProfileCompanyEvaluationGet } from "../asyncThunks/riskProfileCompanyEvaluationGet";
import { riskProfileIndividualEvaluationGet } from "../asyncThunks/riskProfileIndividualEvaluationGet";
import { riskProfileCompanySnapshotUpdate } from "../asyncThunks/riskProfileCompanySnapshotUpdate";
import { riskProfileIndividualSnapshotUpdate } from "../asyncThunks/riskProfileIndividualSnapshotUpdate";
import { riskProfileCompanySnapshotCreate } from "../asyncThunks/riskProfileCompanySnapshotCreate";
import { riskProfileIndividualSnapshotCreate } from "../asyncThunks/riskProfileIndividualSnapshotCreate";
import { riskProfileCompanySnapshotsGet } from "../asyncThunks/riskProfileCompanySnapshotsGet";
import { riskProfileIndividualSnapshotsGet } from "../asyncThunks/riskProfileIndividualSnapshotsGet";
import { riskProfileCompanyApproveUpdate } from "../asyncThunks/riskProfileCompanyApproveUpdate";
import { riskProfileIndividualApproveUpdate } from "../asyncThunks/riskProfileIndividualApproveUpdate";
import { riskProfileCompanyDraftGet } from "../asyncThunks/riskProfileCompanyDraftGet";
import { riskProfileIndividualDraftGet } from "../asyncThunks/riskProfileIndividualDraftGet";
import { riskProfileCompanyArchive } from "../asyncThunks/riskProfileCompanyArchive";
import { riskProfileIndividualArchive } from "../asyncThunks/riskProfileIndividualArchive";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";
import { reduce } from "lodash";

type ProfileResponse = Paths.GetRiskProfileCriteria.Responses.$200;
export type ScoringType = Components.Schemas.Scoring;
export type SnapResponse = Components.Schemas.RiskProfileSnapshotResponse;

type State = {
  riskCriteria: ProfileResponse,
  riskProfileCompany: SnapResponse,
  riskProfileCompanyDraft: SnapResponse,
  riskProfileCompanyEvaluation: ScoringType,
  riskProfileCompanySnapshots: SnapResponse[],
  riskProfileIndividual: SnapResponse,
  riskProfileIndividualDraft: SnapResponse,
  riskProfileIndividualEvaluation: ScoringType,
  riskProfileIndividualSnapshots: SnapResponse[],
  riskProfileCompanySnapUpdated:  SnapResponse,
  riskProfileIndividualSnapUpdated: SnapResponse,
  riskProfileCompanyApproveUpdated:  SnapResponse,
  riskProfileIndividualApproveUpdated: SnapResponse,
  newRiskProfileCompanySnap: SnapResponse,
  newRiskProfileIndividualSnap: SnapResponse,
  riskProfileCompanyArchived: boolean,
  riskProfileIndividualArchived: boolean
};

const emptyScoring = {
    totalScore: 0,
    dueDiligenceScore: '',
    calculatedAt: ''
}

const emptySnap = {
    snapshotId: '',
    createdAt: '',
    data: '',
    isDraft: false,
    isApproved: false,
    isLatestApproved: false,
    isEditable: false,
}

const initialState: State = {
    riskCriteria: [],
    riskProfileCompany: emptySnap,
    riskProfileCompanyDraft: emptySnap,
    riskProfileCompanyEvaluation: emptyScoring,
    riskProfileCompanySnapshots: [],
    riskProfileIndividual: emptySnap,
    riskProfileIndividualDraft: emptySnap,
    riskProfileIndividualEvaluation: emptyScoring,
    riskProfileIndividualSnapshots: [],
    riskProfileCompanySnapUpdated: emptySnap,
    riskProfileIndividualSnapUpdated: emptySnap,
    riskProfileCompanyApproveUpdated: emptySnap,
    riskProfileIndividualApproveUpdated: emptySnap,
    newRiskProfileCompanySnap: emptySnap,
    newRiskProfileIndividualSnap: emptySnap,
    riskProfileCompanyArchived: false,
    riskProfileIndividualArchived: false
};

type CaseReducers<State> = {
    riskProfileCriteriaGetClear: CaseReducerType<State, object>;
    riskProfileCompanyGetClear: CaseReducerType<State, object>;
    riskProfileIndividualGetClear: CaseReducerType<State, object>;
    riskProfileCompanySnapshotUpdateClear: CaseReducerType<State, object>;
    riskProfileIndividualSnapshotUpdateClear: CaseReducerType<State, object>;
    riskProfileCompanySnapshotCreateClear: CaseReducerType<State, object>;
    riskProfileIndividualSnapshotCreateClear: CaseReducerType<State, object>;
    riskProfileCompanySnapshotsGetClear: CaseReducerType<State, object>;
    riskProfileIndividualSnapshotsGetClear: CaseReducerType<State, object>;
    riskProfileCompanyEvaluationGetClear: CaseReducerType<State, object>;
    riskProfileIndividualEvaluationGetClear: CaseReducerType<State, object>;
    riskProfileCompanyApproveUpdateClear: CaseReducerType<State, object>;
    riskProfileIndividualApproveUpdateClear: CaseReducerType<State, object>;
    riskProfileCompanyDraftGetClear: CaseReducerType<State, object>;
    riskProfileIndividualDraftGetClear: CaseReducerType<State, object>;
    riskProfileCompanyArchiveClear: CaseReducerType<State, object>;
    riskProfileIndividualArchiveClear: CaseReducerType<State, object>;
};  

export const riskSlice = createSlice<State, CaseReducers<State>>({
  name: "risk",
  initialState,
  reducers: {
    riskProfileCriteriaGetClear: (state, { payload }) => {
        state.riskCriteria = initialState.riskCriteria
    },
    riskProfileCompanyGetClear: (state, { payload }) => {
        state.riskProfileCompany = initialState.riskProfileCompany
    },
    riskProfileIndividualGetClear: (state, { payload }) => {
        state.riskProfileIndividual = initialState.riskProfileIndividual
    },
    riskProfileCompanySnapshotUpdateClear: (state, { payload }) => {
        state.riskProfileCompanySnapUpdated = initialState.riskProfileCompanySnapUpdated
    },
    riskProfileIndividualSnapshotUpdateClear: (state, { payload }) => {
        state.riskProfileIndividualSnapUpdated = initialState.riskProfileIndividualSnapUpdated
    },
    riskProfileCompanySnapshotCreateClear: (state, { payload }) => {
        state.newRiskProfileCompanySnap = initialState.newRiskProfileCompanySnap
    },
    riskProfileIndividualSnapshotCreateClear: (state, { payload }) => {
        state.newRiskProfileIndividualSnap = initialState.newRiskProfileIndividualSnap
    },
    riskProfileCompanySnapshotsGetClear: (state, { payload }) => {
        state.riskProfileCompanySnapshots = initialState.riskProfileCompanySnapshots
    },
    riskProfileIndividualSnapshotsGetClear: (state, { payload }) => {
        state.riskProfileIndividualSnapshots = initialState.riskProfileIndividualSnapshots
    },
    riskProfileCompanyEvaluationGetClear: (state, { payload }) => {
        state.riskProfileCompanyEvaluation = initialState.riskProfileCompanyEvaluation
    },
    riskProfileIndividualEvaluationGetClear: (state, { payload }) => {
        state.riskProfileIndividualEvaluation = initialState.riskProfileIndividualEvaluation
    },
    riskProfileCompanyApproveUpdateClear: (state, { payload }) => {
        state.riskProfileCompanyApproveUpdated = initialState.riskProfileCompanyApproveUpdated
    },
    riskProfileIndividualApproveUpdateClear: (state, { payload }) => {
        state.riskProfileIndividualApproveUpdated = initialState.riskProfileIndividualApproveUpdated
    },
    riskProfileCompanyDraftGetClear: (state, { payload }) => {
        state.riskProfileCompanyDraft = initialState.riskProfileCompanyDraft
    },
    riskProfileIndividualDraftGetClear: (state, { payload }) => {
        state.riskProfileIndividualDraft = initialState.riskProfileIndividualDraft
    },
    riskProfileCompanyArchiveClear: (state, { payload }) => {
        state.riskProfileCompanyArchived = initialState.riskProfileCompanyArchived
    },
    riskProfileIndividualArchiveClear: (state, { payload }) => {
        state.riskProfileIndividualArchived = initialState.riskProfileIndividualArchived
    },
  },
  extraReducers: (builder) => {
    builder.addCase(riskProfileCriteriaGet.fulfilled, (state, action) => {
        state.riskCriteria = action.payload;
    });
    builder.addCase(riskProfileCompanyGet.fulfilled, (state, action) => {
        state.riskProfileCompany = action.payload;
    });
    builder.addCase(riskProfileIndividualGet.fulfilled, (state, action) => {
        state.riskProfileIndividual = action.payload;
    });
    builder.addCase(riskProfileCompanySnapshotUpdate.fulfilled, (state, action) => {
        state.riskProfileCompanySnapUpdated = action.payload;
    });
    builder.addCase(riskProfileIndividualSnapshotUpdate.fulfilled, (state, action) => {
        state.riskProfileIndividualSnapUpdated = action.payload;
    });
    builder.addCase(riskProfileCompanySnapshotCreate.fulfilled, (state, action) => {
        state.newRiskProfileCompanySnap = action.payload;
    });
    builder.addCase(riskProfileIndividualSnapshotCreate.fulfilled, (state, action) => {
        state.newRiskProfileIndividualSnap = action.payload;
    });
    builder.addCase(riskProfileCompanySnapshotsGet.fulfilled, (state, action) => {
        state.riskProfileCompanySnapshots = action.payload;
    });
    builder.addCase(riskProfileIndividualSnapshotsGet.fulfilled, (state, action) => {
        state.riskProfileIndividualSnapshots = action.payload;
    });
    builder.addCase(riskProfileCompanyEvaluationGet.fulfilled, (state, action) => {
        state.riskProfileCompanyEvaluation = action.payload;
    });
    builder.addCase(riskProfileIndividualEvaluationGet.fulfilled, (state, action) => {
        state.riskProfileIndividualEvaluation = action.payload;
    });
    builder.addCase(riskProfileCompanyApproveUpdate.fulfilled, (state, action) => {
        state.riskProfileCompanyApproveUpdated = action.payload;
    });
    builder.addCase(riskProfileIndividualApproveUpdate.fulfilled, (state, action) => {
        state.riskProfileIndividualApproveUpdated = action.payload;
    });
    builder.addCase(riskProfileCompanyDraftGet.fulfilled, (state, action) => {
        state.riskProfileCompanyDraft = action.payload;
    });
    builder.addCase(riskProfileIndividualDraftGet.fulfilled, (state, action) => {
        state.riskProfileIndividualDraft = action.payload;
    });
    builder.addCase(riskProfileCompanyArchive.fulfilled, (state, action) => {
        state.riskProfileCompanyArchived = action.payload;
    });
    builder.addCase(riskProfileIndividualArchive.fulfilled, (state, action) => {
        state.riskProfileIndividualArchived = action.payload;
    });
  },
});

export const { 
    riskProfileCriteriaGetClear, 
    riskProfileCompanyGetClear,
    riskProfileIndividualGetClear,
    riskProfileCompanySnapshotUpdateClear,
    riskProfileIndividualSnapshotUpdateClear,
    riskProfileCompanySnapshotCreateClear,
    riskProfileIndividualSnapshotCreateClear,
    riskProfileCompanySnapshotsGetClear,
    riskProfileIndividualSnapshotsGetClear,
    riskProfileCompanyEvaluationGetClear,
    riskProfileIndividualEvaluationGetClear,
    riskProfileCompanyApproveUpdateClear,
    riskProfileIndividualApproveUpdateClear,
    riskProfileCompanyDraftGetClear,
    riskProfileIndividualDraftGetClear,
    riskProfileCompanyArchiveClear,
    riskProfileIndividualArchiveClear
} = riskSlice.actions;
export default riskSlice.reducer;
