import { FC } from 'react'
import { Result } from 'antd'


const Error403: FC = () => (
    <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        />
)

export default Error403