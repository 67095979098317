import { FC } from 'react'
import { Row, Col } from 'antd'
import { useSelector } from '../../hooks/useSelector'
import styled from 'styled-components'
import { KycGetPdfBtn } from './pdf/KycGetPdfBtn'

const CenteredCol = styled(Col)`
	text-align: center;
	margin-top: 30px;
	margin-bottom: 30px;
`

const KycPrint: FC = () => {
	const {kycApplication} = useSelector((state) => state.user2.kycApplication)
	return (
		<Row justify='center'>
			<CenteredCol>
				<KycGetPdfBtn item={kycApplication} />
			</CenteredCol>
		</Row>
			 
	)
}

export default KycPrint
