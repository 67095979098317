import { createAsyncThunk } from "@reduxjs/toolkit"
import { AsyncThunkActionType } from "../../store"
import { AsyncThunkDataType } from "../../common"
import { Paths } from "api/kyc/generated/client2"
import { api2 } from "api/kyc/api"
import { Components } from "api/kyc/generated/client2"

type PathParameters = Paths.GetBusinessProfiles.QueryParameters
export type BusinessProfileResponse = Components.Schemas.BusinessProfile

export const businessProfilesGet: AsyncThunkActionType<
  BusinessProfileResponse[],
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("businessProfilesGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api2.getBusinessProfiles(data.params, null, data?.config);
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
