import { useEffect, FC } from 'react'
import { Outlet } from 'react-router-dom'
import { useActionStatus } from 'hooks/useActionStatus'
import { identityGet } from 'store/kyc-front/asyncThunks/identityGet'
import { CoverPage } from 'modules/kyc-front/ui-elements/CoverPage'
import { useKeycloakRefreshToken, useIsUserLogged } from 'modules/keycloak'
import { useSelector } from 'hooks/useSelector'
import { dispatch } from 'store/store'
import { setEnvs } from 'store/kyc-front/slices/envsSlice'
import ScrollToTop from 'utilities/ScrollToTop'
import { logout } from 'modules/keycloak'
import {ENV} from "../../index"

interface configProps {
    apiURL?:  string,
	adminSuffix?: string,
	token?: string,
	profileId: string,
	type: string,
	env?: string,
	accountType: 'PRIVATE' | 'BUSINESS' | '',
	routeSuffix: string
}

const KycKeycloakFront: FC = () => {
	let config: configProps = {
		apiURL: ENV.API_URL,
		env: ENV.ENV,
		profileId: '',
		accountType: '',
		type: 'standalone',
		routeSuffix: '/user'
	}
	useKeycloakRefreshToken({
		keycloakDomain: ENV.KEYCLOAK_DOMAIN,
		onRefreshError:  () => dispatch(logout(true)),
        client_id: ENV.KEYCLOAK_CLIENT_FRONT,
		is_backend: false
	});
	const identity = useSelector((state) => state.general.identity);
	const envs = useSelector((state) => state.general.envs);
	const gettingProfileStatus = useActionStatus(identityGet);
	const { isUserLogged } = useIsUserLogged();
	const { env, type, routeSuffix, apiURL, adminSuffix } = config;
	useEffect(() => {
		if(isUserLogged) {
			if(identity.identity.kind !== '') {
				dispatch(setEnvs({type, env, profileId: identity.identity.id, admin: false, routeSuffix, accountType: identity.identity.kind === 'company' ? 'BUSINESS' : 'PRIVATE', apiURL, adminSuffix}));
			} else {
				if(!gettingProfileStatus.isPending && !gettingProfileStatus.isFullfilled) {
					dispatch(identityGet());
				}
			}
		}
	}, [identity, isUserLogged]);
	return (envs.accountType !== '' ? <><ScrollToTop /><Outlet /></> : isUserLogged ? <CoverPage visible={true} /> : <Outlet /> )
}

export default KycKeycloakFront