import { FC, useEffect } from 'react'
import _ from 'lodash';
import { Row, Col, Select, Space } from 'antd'
import { countries } from 'countries-list'
import { useSelector } from '../../../hooks/useSelector'
import { dispatch } from '../../../store/store'
import Title from 'antd/es/typography/Title'
import styled from 'styled-components'
import FormInput from '../ui-elements/FormInput'
import FormSelect from '../ui-elements/FormSelect'
import Well from '../ui-elements/Well'
import { FormCheckboxRadio } from '../ui-elements/FormCheckboxRadio'
import { Components } from "../../../api/kyc/generated/client"

const FormCheckboxRadioCustom = styled.div`
    display: flex;
    .ant-form-item-label {
        order: 2;
        margin-left: 15px;
        margin-top: 15px;
    }
    .checkContainer {
        order: 1;
        min-width: 330px;
        margin-top: 15px;
    }
`


type addressType = Components.Schemas.AddressEntry | undefined;

interface KProps {
    values: any,
    errors: any,
    setFieldValue: any,
    handleInputChange: any,
    onSelectChange: any,
    printRender?: boolean,
    disabled?: boolean
}


const KycCompanyDetailsForm: FC<KProps> = ({values, errors, setFieldValue, handleInputChange, onSelectChange, disabled, printRender}) => {
    const { Option } = Select;
    const envs = useSelector((state) => state.general.envs);
    const isOfficeSame = values.headOfficeAddress?.sameAsRegistered
    const handleCheckChange = (el: any) => {
        setFieldValue(el.target.name, JSON.parse(el.target.value.toLowerCase()))
        if(el.target.value === 'true') {
            setFieldValue('headOfficeAddress.address', '');
            setFieldValue('headOfficeAddress.postcode', '');
            setFieldValue('headOfficeAddress.city', '');
            setFieldValue('headOfficeAddress.country', '');
        }
        handleInputChange();
    }
    return (
        <Space direction='vertical' size={'large'}>
            <FormInput label='Address *' name='registeredAddress.address' error={errors.registeredAddress?.address} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.registeredAddress?.address} type='company' />
            <Row gutter={30}>
                <Col span={12}>
                    <FormInput label='Postcode *' name='registeredAddress.postcode' error={errors.registeredAddress?.postcode} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.registeredAddress?.postcode} type='company' />
                </Col>
                <Col span={12}>
                    <FormInput label='City *' name='registeredAddress.city' error={errors.registeredAddress?.city} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.registeredAddress?.city} type='company' />
                </Col>
            </Row>
            <Row gutter={30}>
                <Col span={12}>
                    <FormSelect defaultValue={values.registeredAddress?.country} label='Country' id='registeredAddress.country' error={errors.registeredAddress?.country} placeholder='Select country' onChange={(val) => onSelectChange(val, 'registeredAddress.country')} disabled={disabled} type='company' >
                        {Object.keys(countries).map((val, i) => {
                            const country = countries[val as keyof typeof countries]
                            return <Option key={i} value={val}>{country.name}</Option>
                        })}
                    </FormSelect>
                </Col>
            </Row>
            <Title level={4} className='title4' style={printRender ? isOfficeSame === 'false' ? {'marginTop': '-5px', 'marginBottom': '-5px'} : {'marginBottom': '-5px'} : {'marginBottom': '-25px'}}>Address of head office</Title>
            <Row className={isOfficeSame === 'false' ? 'hide-print' : ''}>
                <Col span={13}>
                    <FormCheckboxRadioCustom>
                        <FormCheckboxRadio id='headOfficeAddress.sameAsRegistered' type='radio' onChange={handleCheckChange} error={errors.headOfficeAddress?.sameAsRegistered}
                            options={[{
                                label: 'same as registered address',
                                value: 'true'
                            },
                            {
                                label: 'other',
                                value: 'false'
                            }]
                        } disabled={disabled} showAdditionalApprove={true} approveType='company' />
                    </FormCheckboxRadioCustom>
                </Col>
            </Row>
            {isOfficeSame === 'false' || envs.admin ? (
                <Well className='well'>
                    <Space size={15} direction='vertical' style={printRender ? {'marginTop' :'-10px'} : {}}>
                        <FormInput label='Street and number *' name='headOfficeAddress.address' error={errors.headOfficeAddress?.address} onChange={handleInputChange} disabled={isOfficeSame === 'true' && envs.admin || disabled} printRender={printRender} value={values.headOfficeAddress?.address} type='company' placeholder={isOfficeSame === 'true' ? values.registeredAddress.address : ''} />
                        <Row gutter={30}>
                                <Col span={12}>
                                    <FormInput label='Postcode *' name='headOfficeAddress.postcode' error={errors.headOfficeAddress?.postcode} onChange={handleInputChange} disabled={isOfficeSame === 'true' && envs.admin || disabled} printRender={printRender} value={values.headOfficeAddress?.postcode} type='company' placeholder={isOfficeSame === 'true' ? values.registeredAddress.postcode : ''} />
                                </Col>
                                <Col span={12}>
                                    <FormInput label='City *' name='headOfficeAddress.city' error={errors.headOfficeAddress?.city} onChange={handleInputChange} disabled={isOfficeSame === 'true' && envs.admin || disabled} printRender={printRender} value={values.headOfficeAddress?.city} type='company' placeholder={isOfficeSame === 'true' ? values.registeredAddress?.city : ''}  />
                                </Col>
                            </Row>
                        <Row gutter={30} style={printRender ? {'marginBottom' :'-10px'} : {}}>
                            <Col span={12}>
                                {/*@ts-ignore*/}
                                <FormSelect label='Country *' defaultValue={values.headOfficeAddress?.country}  id='headOfficeAddress.country' error={errors.headOfficeAddress?.country} onChange={(val) => onSelectChange(val, 'headOfficeAddress.country')} disabled={isOfficeSame === 'true' && envs.admin || disabled} type='company' placeholder={isOfficeSame === 'true' ? values.registeredAddress?.country ?  countries[values.registeredAddress?.country].name : '' : 'Select country'}  >
                                    {Object.keys(countries).map((val, i) => {
                                        const country = countries[val as keyof typeof countries]
                                        return <Option key={i} value={val}>{country.name}</Option>
                                    })}
                                </FormSelect>
                            </Col>
                        </Row>
                    </Space>
                </Well>
            ) : (<></>)}
        </Space>
    )
	
}

export default KycCompanyDetailsForm
