import { FC, useEffect, useState } from 'react'
import _ from 'lodash'
import { Row, Col, Select, Space, Typography } from 'antd'
import { themeColor } from 'styles/themeStyles'
import { useSelector } from 'hooks/useSelector'
import { dispatch } from 'store/store'
import styled from 'styled-components'
import FormSelect from '../ui-elements/FormSelect'
import KycNextSteps from 'modules/kyc-front/KycNextSteps'
import { kycApplicationUpdate } from 'store/kyc-front/asyncThunks/kycApplicationUpdate'
import { useActionStatus } from 'hooks/useActionStatus'
import { kycApplicationGet } from 'store/kyc-front/asyncThunks/kycApplicationGet'
import { kycApplicationValidate } from 'store/kyc-front/asyncThunks/kycApplicationValidate'
import { descriptions } from './common'
import { kycApplicationSubmit } from 'store/kyc-front/asyncThunks/kycApplicationSubmit'
const { Text } = Typography

const Muted = styled.p`
    color: ${themeColor.gray}
`

const Signature = styled.div`
    border: 1px solid ${themeColor.grayLight};
    padding: 5px 10px;
    height: 100px;
    min-width: 200px;
    margin-top: 15px;
    margin-right: 15px;
    text-align: center;
`

interface KProps {
    values: any,
    errors: any,
    handleInputChange: any,
    onSelectChange: any,
    onDateChange: any,
    setFieldValue: any,
    printRender?: boolean,
    disabled?: boolean
}


const KycCompanySignatoriesForm: FC<KProps> = ({values, errors, disabled, printRender, onSelectChange}) => {
    const { Option } = Select
    const envs = useSelector((state) => state.general.envs)
    const {kycApplication, validationFullResponse} = useSelector((state) => state.user2.kycApplication)
    const {isFullfilled, isError} = useActionStatus(kycApplicationUpdate)
    const [showError, setShowError] = useState(false)
    const submitStatus = useActionStatus(kycApplicationSubmit)
    //@ts-ignore
    const aps = kycApplication.values.authorizedPersons
    //@ts-ignore
    const signatoriesList = aps?.filter(person => person.isSignatory === "true")
    const signatoryError = validationFullResponse.payload?.validationErrors?.find((err: any) => err.ref === 'authorizedPersons' && err.slug === 'signatories')
    useEffect(() => {
            if(submitStatus.isCleared) {
                setShowError(true)
            }
        
    }, [submitStatus.isCleared])
    useEffect(() => {
        dispatch(kycApplicationGet({params: {id: envs.profileId}}))
        dispatch(kycApplicationValidate({params: {id: envs.profileId}}))
    }, [isFullfilled, isError])
    const onSignatoryChange = (el: any) => {
        onSelectChange(el, 'signatories')
        //@ts-ignore
        const newSignatories = aps?.map((sign: string) => ({id: sign.id, isSignatory: el.find((pers: string) => pers === sign.id) ? true :  false}))
        //@ts-ignore
        dispatch(kycApplicationUpdate({params: {id: envs.profileId}, data: {authorizedPersons: newSignatories}}))
    }
    return (
        <Space direction='vertical' size={'large'}>
            <div>
                
                
                <p>1. {descriptions.signatoriesDeclarations1}</p>
                
                <p>2. {descriptions.signatoriesDeclarations2}</p>
                
                <p>3. {descriptions.signatoriesDeclarations3_1} <b>{descriptions.signatoriesDeclarations3_2}</b> {descriptions.signatoriesDeclarations3_3}</p>
                
                <Muted>{descriptions.signatoriesDeclarations4}</Muted>
                
                {printRender ? (
                  <>
                      <Text>Signatures of authorized persons:</Text>
                      <Row>
                          {/*@ts-ignore*/}
                          {signatoriesList?.map((ap, i) => {
                              return <Col><Signature
                                key={'signature' + i}><span>{ap.firstName ? ap.firstName : 'New'} {ap.lastName ? ap.lastName : 'Person'}</span></Signature></Col>
                          })}
                      </Row>
                  </>
                ) : (
                  <>
                      <KycNextSteps />
                      <br />
                      <Row gutter={30}>
                          <Col md={24}>
                              <FormSelect error={showError ? signatoryError?.errorType : ''} defaultValue={signatoriesList?.map((el: any) => ({
                                  label: `${el.firstName ? el.firstName : 'New'} ${el.lastName ? el.lastName : 'Person'}`,
                                  value: el.id
                              }))} mode="multiple" label='Who will be signing the documents? *' id='isSignatory'
                                          placeholder='Select at least one authorized person'
                                          onChange={(val) => onSignatoryChange(val)} disabled={disabled} type='company'>
                                  {/*@ts-ignore*/}
                                  {kycApplication.values.authorizedPersons?.map((ap, i) => {
                                      if (ap.roleType && ap.roleType === 'Director' && ap.isSignatory !== 'true') {
                                          return <Option key={'signatories' + i}
                                                         value={ap.id}>{ap.firstName ? ap.firstName : 'New'} {ap.lastName ? ap.lastName : 'Person'}</Option>
                                      }
                                      
                                  })}
                              </FormSelect>
                          </Col>
                      </Row>
                      <br />
                      <Muted>(Signatory must be authorized to sign for the company)</Muted>
                      <br />
                      <br />
                  </>
                )}
            </div>
        </Space>
    )
    
}

export default KycCompanySignatoriesForm
