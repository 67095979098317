import { FC } from 'react'
import _ from 'lodash';
import { Row, Col, Space, Select } from 'antd'
import { countries } from 'countries-list'
import { Regions } from 'utilities/Regions'
import FormInput from 'modules/kyc-front/ui-elements/FormInput'
import FormSelect from 'modules/kyc-front/ui-elements/FormSelect'
import { FormCheckboxRadio } from 'modules/kyc-front/ui-elements/FormCheckboxRadio'

interface KProps {
    values: any,
    errors: any,
    handleInputChange: any,
    onSelectChange: any,
    printRender?: boolean,
    disabled?: boolean
}


const KycCompanyOperationsForm: FC<KProps> = ({values, errors, handleInputChange, onSelectChange, disabled, printRender }) => {
    const { Option } = Select;
    const countriesAndRegions = {...Regions, ...countries};
    return (
        <Space direction='vertical' size={'large'}>
            <Row gutter={30}>
                <Col span={24}>
                    <FormSelect mode="multiple" defaultValue={values.geographicalArea !== '' ? values.geographicalArea?.split(',') : values.geographicalArea} label='Main countries and regions of geographical area of operation *' id='geographicalArea' error={errors.geographicalArea} placeholder='Select at least one country' onChange={(val) => onSelectChange(val, 'geographicalArea')} disabled={disabled} type='company' >
                        {Object.keys(countriesAndRegions).map((val, i) => {
                            const country = countriesAndRegions[val as keyof typeof countriesAndRegions]
                            return <Option key={i} value={val}>{country.name}</Option>
                        })}
                    </FormSelect>
                </Col>
            </Row>
            <Row>
                <Col span={13}>
                    <FormCheckboxRadio className={'checkboxCompanyAddress'} label='Number of direct employees' id='numberOfEmployees' error={errors.numberOfEmployees} type='radio' onChange={handleInputChange}
                        options={[{
                                label: 'None',
                                value: '0'
                            },
                            {
                                label: '1 - 10',
                                value: '1'
                            },
                            {
                                label: '11 - 100',
                                value: '11'
                            },
                            {
                                label: '> 100',
                                value: '101'
                            }
                        ]
                    } disabled={disabled} approveType='company' />
                </Col>
            </Row>
            <FormInput label='Nature of business activities and business model *' subLabel='(please explain in 2-3 sentences)' subLabelClass={'companySubLabelMargin1'}  name='activitiesAndBusinessModel' id="activitiesAndBusinessModelTextarea" error={errors.activitiesAndBusinessModel} component='textarea' onChange={handleInputChange} disabled={disabled} value={values.activitiesAndBusinessModel} printRender={printRender} type='company' />
            <FormInput className={'textarea-small-print'} label='Does the company have any business license or is supervised by a financial markets regulator? *' subLabel='(please name authority and add a copy of license or link to authority website 
                    where license can be looked up)' subLabelClass={'companySubLabelMargin2'}  name='businessLicence' id="businessLicenceTextarea" error={errors.businessLicence} component='textarea' onChange={handleInputChange} disabled={disabled} value={values.businessLicence} printRender={printRender} type='company' />
            <Row gutter={30}>
                <Col span={13}>
                    <FormInput label='Approximate annual turnover (EUR) *' name='annualTurnoverEUR' error={errors.annualTurnoverEUR} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.annualTurnoverEUR} type='company' formatting='numbers' />
                </Col>
            </Row>
        </Space>
    )
	
}

export default KycCompanyOperationsForm
