export const Regions = {
    "RWW": {name: "Worldwide"},
    "REU": {name: "Europe"},
    "RSE": {name: "Southern Europe"},
    "RWE": {name: "Western Europe"},
    "REE": {name: "Eastern Europe"},
    "RCE": {name: "Central Europe"},
    "RSC": {name: "Scandinavia"},
    "RBA": {name: "Balkans"},
    "RBC": {name: "Baltic Countries"},

    "RAI": {name: "Asia"},
    "RWA": {name: "Western Asia"},
    "RCA": {name: "Central Asia"},
    "RSA": {name: "Southern Asia"},
    "RSO": {name: "South-East Asia"},
    "REA": {name: "Eastern Asia"},

    "RAF": {name: "Africa"},
    "RWF": {name: "Western Africa"},
    "RSF": {name: "Southern Africa"},
    "RCF": {name: "Central Africa"},
    "RNF": {name: "Northern Africa"},
    "REF": {name: "Eastern Africa"},

    "RNM": {name: "North America"},
    "RSM": {name: "South America"},
    "RCM": {name: "Central America"},
    "RAS": {name: "American Samoa"},

    "RCR": {name: "Caribbean"},
    "RAU": {name: "Australia and Oceania"}
}