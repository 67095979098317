import { PATH } from 'routes/AppRoutes'
import { useIsLoginPage } from 'hooks/useIsLoginPage'
import { setBackToUri, useIsUserLogged } from 'modules/keycloak'
import { FC, ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { dispatch } from 'store/store'
import { useSelector } from 'hooks/useSelector'

/**
 * If user is not logged, redirect to LOGIN_PAGE
 * Do not do that if client manually clicked logout action, to not redirect to auth
 */
const RedirectIfNotLogged: FC<{ children?: ReactNode }> = ({ children }) => {
	const isLoginPage = useIsLoginPage()
	const { pathname, search } = useLocation()
	const { isUserLogged } = useIsUserLogged()
	const { isForbidden } = useSelector(state => state.general.forbidden);
	if (!isUserLogged && !isLoginPage) {
		dispatch(setBackToUri(`${pathname}${search ? `${search}` : ''}`))
		return <Navigate to={pathname.includes('admin') ? PATH.ADMIN_LOGIN : PATH.LOGIN} replace />
	}
	if(isForbidden) {
		return <Navigate to={'/403'} replace />
	}
	return <>{children}</>
}
export const PrivateRoute: FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<RedirectIfNotLogged>
			{children}
		</RedirectIfNotLogged>
	)
}
