import { Typography, Skeleton } from 'antd'
import _ from 'lodash'
import Title from 'antd/es/typography/Title'
import { useSelector } from '../../../hooks/useSelector'
import { PageContent } from '../../layout/PageContent'
import { dispatch } from '../../../store/store'
import { FC, useEffect } from 'react'
import KycLayout, { NavItem } from '../../kyc-front/kyc-layout/KycLayout'
import { MessagesListing } from '../../kyc-front/ui-elements/Comment'
import { adminMessagesGet } from '../../../store/kyc-backend/asyncThunks/adminMessagesGet'
import { adminMessagesGetClear } from '../../../store/kyc-backend/slices/adminInboxSlice'
import { companyBackMessagePost } from '../../../store/kyc-backend/asyncThunks/companyBackMessagePost'
import { companyBackMessageViewedPut } from '../../../store/kyc-backend/asyncThunks/companyBackMessageViewedPut'
import { individualBackMessageViewedPut } from '../../../store/kyc-backend/asyncThunks/individualBackMessageViewedPut'
import { companyBackMessagePostClear, individualBackMessagePostClear, companyBackMessageViewedPutClear, individualBackMessageViewedPutClear } from '../../../store/kyc-backend/slices/messagesSlice'
import { individualBackMessagePost } from '../../../store/kyc-backend/asyncThunks/individualBackMessagePost'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { clientsGet } from 'store/kyc-backend/asyncThunks/clientsGet'

const AdminMessages: FC = () => {
    const adminInbox = useSelector((state) => state.admin.adminInbox);
    const backMessages = useSelector((state) => state.admin.backMessages);
    const clientsGetStatus = useActionStatus(clientsGet);
	useEffect(() => {
        dispatch(clientsGet())
        dispatch(adminMessagesGet({params: null}))
    }, []);
	return (
        <KycLayout listingView={true} pageName='Messages' nav={{hideNav: true, step: -1, navPage: NavItem.MESSAGES}}>
    		<PageContent >
    			<Title level={2} style={{marginTop: -40, marginBottom: 0}}>Messages</Title> 
                {clientsGetStatus.isError ? (
                        <p>Error</p>
                ) : (
                    <>
                        {clientsGetStatus.isFullfilled ? (
                            <MessagesListing 
                                type='message' 
                                side='admin' 
                                msgGet={adminMessagesGet} 
                                msgGetClear={adminMessagesGetClear} 
                                msgPostBusiness={companyBackMessagePost} 
                                msgPostPrivate={individualBackMessagePost}
                                msgPostBusinessClear={companyBackMessagePostClear} 
                                msgPostPrivateClear={individualBackMessagePostClear} 
                                msgViewedPutBusiness={companyBackMessageViewedPut}
                                msgViewedPutPrivate={individualBackMessageViewedPut}
                                msgViewedPutBusinessClear={companyBackMessageViewedPutClear}
                                msgViewedPutPrivateClear={individualBackMessageViewedPutClear}
                                //@ts-ignore
                                listing={adminInbox.messages && adminInbox.messages }
                                wasViewedBusiness={backMessages.companyMessageRead}
                                wasViewedPrivate={backMessages.individualMessageRead}
                                msgElemSentBusiness={backMessages.companyMessageSent}
                                msgElemSentPrivate={backMessages.individualMessageSent}
                                adminInbox={true}
                            />
                        ) : (<Skeleton style={{marginTop: 30}} paragraph={{ rows: 10 }} active />)}
                    </>
                )}
    		</PageContent>
        </KycLayout>
	)
}

export default AdminMessages
