import { noop } from 'lodash'
import { ILogoutParams, logout as keycloakLogout } from './api/keycloakActions'

type IProps = {
	callback: (() => void) | string
	onBeforeLogout?: () => void
} & ILogoutParams
/**
 * Use it to logout user & redirect to path
 * @param callback - set path to redirect after logout or set own redirect function
 * @param onBeforeLogout - callback before clear auth store
 * @param params - other logout parameters
 */
export const logoutUser = ({ callback, onBeforeLogout = noop, ...params }: IProps): Promise<any> => {
	return keycloakLogout(params).finally(() => {
		onBeforeLogout()

		if (typeof callback === 'function') {
			setTimeout(callback, 200)
		} else {
			setTimeout(() => {
				window.location.href = callback
			}, 200)
		}
	})
}
