import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "../../store"
import { AsyncThunkDataType } from "../../common"
import { Components, Paths } from "api/kyc/generated/client2"

type PathParameters = Paths.UpdateSection.PathParameters
type RequestBody = Paths.UpdateSection.RequestBody
type Response = Paths.UpdateSection.Responses.$200


export const kycApplicationSectionUpdate: AsyncThunkActionType<
Response,
  AsyncThunkDataType<PathParameters, RequestBody, "params">
> = createAsyncThunk("kycApplicationSectionUpdate", async ({ params, data, config }, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    let response = await api2.updateSection(params, data, config);
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});