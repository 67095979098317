/**
 * font-size
 */
export interface IThemeSizes {
  mini: string;
  sm: string;
  base: string;
  lg: string;
  heading5: string;
  heading4: string;
  heading3: string;
  heading2: string;
  heading1: string;
}
export const themeSize: { [k in keyof IThemeSizes]: string } = {
  mini: "10px", // additional for fx
  sm: "12px", // @font-size-sm
  base: "14px", // @font-size-base
  lg: "16px", // @font-size-lg
  heading5: "18px", // @heading-5-size
  heading4: "20px", // @heading-4-size
  heading3: "24px", // @heading-3-size
  heading2: "30px", // @heading-2-size
  heading1: "38px", // @heading-1-size
};

/**
 * screen width
 */
export interface IThemeScreen {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
  maxContent: string;
  midContent: string;
  smallContent: string;
}
export const themeScreen: { [k in keyof IThemeScreen]: string } = {
  xs: "480px", // @screen-xs
  sm: "576px", // @screen-sm
  md: "768px", // @screen-md
  lg: "992px", // @screen-lg
  xl: "1300px", // @screen-xl
  xxl: "1600px", // @screen-xxl

  // Inner content sizes
  maxContent: "1300px", // max content for this app
  midContent: "900px", // usually used middle content for this app
  smallContent: "650px", // usually used small content for this app
};

/**
 * font-weight
 */
export interface IThemeWeight {
  normal: number;
  bold: number;
  extraBold: number;
}
export const themeWeight: IThemeWeight = {
  normal: 400,
  bold: 500,
  extraBold: 600,
};

/**
 * special colors
 */
export interface IThemeColor {
  blueDark: string;
  blue: string;
  blueLight: string;
  blueGray: string;
  green: string;
  greenGray: string;
  grayDarker: string;
  grayDark: string;
  gray: string;
  grayOpaque: string;
  grayLight: string;
  grayBasic: string;
  grayWhite: string;
  grayBlack: string;
  blueWhite: string;
  orange: string;
  orangeWarning: string;
  pink: string;
  red: string;
  redDark: string;
  primaryColor: string;
  primaryLight: string;
}
export const themeColor: IThemeColor = {
  blueDark: "#000066",
  blue: "#6699FF", // @primary-color
  blueLight: "#E0EBFF",
  blueGray: "#92ade3",
  green: "#33CC99",
  greenGray: "#7fb5a3",
  grayDarker: "#4F4F4E",
  grayDark: "#6B6B7D",
  gray: "#9494A1",
  grayOpaque: 'rgba(0, 0, 0, 0.06)',
  grayLight: "#CCCCCC",
  grayBasic: '#d9d9d9',
  grayWhite: "#F2F2F7",
  grayBlack: "#43434d",
  blueWhite: "#f7fbff",
  orange: "#FFCC00",
  orangeWarning: '#faad14',
  pink: "#FF0066",
  red: "#FF4D4F",
  redDark: "#e74547",
  primaryColor: "#6699FF",
  primaryLight: "#E0EBFF"
};
