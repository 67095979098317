import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "../../store"
import { AsyncThunkDataType } from "../../common"
import { Components, Paths } from "api/kyc/generated/client2"

type PathParameters = Paths.AddNewSection.PathParameters
type Response = Components.Schemas.KycApplicationSection


export const kycApplicationSectionCreate: AsyncThunkActionType<
Response,
  AsyncThunkDataType<PathParameters, "data", "params">
> = createAsyncThunk("kycApplicationSectionCreate", async ({ params, data, config }, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    let response = await api2.addNewSection(params, data, config);
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});