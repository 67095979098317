import { countries } from 'countries-list'
import { Regions } from './Regions'

const translateCountries = (value?: string): string => {
    const countriesAndRegions = {...Regions, ...countries};
	if(!value){
		return ''
	}
	const splitCountries = value.split(',')
	return splitCountries.map(key => countriesAndRegions[key as keyof typeof countries]?.name).join(', ')
}

export default translateCountries