import { useSelector } from './useSelector'
import { AsyncThunkActionType } from '../store/store'

export type ResponseInfo = {
	isPending?: boolean
	isError?: boolean,
	isFullfilled?: boolean,
	isCleared?: boolean
}

/**
 * get status action from responseInfo reducer
 */
export const useActionStatus = (action: AsyncThunkActionType<any, any>): ResponseInfo => {
	const { status } = useSelector((state) => state.general.responseInfo[action?.typePrefix]) || {}
	return {
		isPending: status === 'pending',
		isError: status === 'rejected',
		isFullfilled: status === 'fulfilled',
		isCleared: status === 'cleared'
	}
}
