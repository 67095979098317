import { Divider} from 'antd'
import { FC } from 'react'
import styled from 'styled-components'

const DivFirst = styled(Divider)`
	margin-top: 0;
`

interface DProps {
    className?: string
}

export const DividerFirst: FC<DProps> = ({className}) => <DivFirst className={className ? className : ''} />
