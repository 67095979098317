import { createSlice } from "@reduxjs/toolkit";
import { Paths } from "../../../api/kyc-backend/generated/client"
import { companyDocumentApproveUpdate } from "../asyncThunks/companyDocumentApproveUpdate"
import { individualDocumentApproveUpdate } from "../asyncThunks/individualDocumentApproveUpdate"
import { CaseReducerType } from "../../store"

export type IndividualDocResponse = Paths.ApproveIndividualDocument.Responses.$200;
export type CompanyDocResponse = Paths.ApproveCompanyDocument.Responses.$200;

type State = {
  //document
  individualDocumentApproveUpdated: {name: string, data: IndividualDocResponse};
  companyDocumentApproveUpdated: {name: string, data: CompanyDocResponse};
};

const initialState: State = {
  individualDocumentApproveUpdated: {name: '', data: false},
  companyDocumentApproveUpdated: {name: '', data: false}
};

type CaseReducers<State> = {
  individualDocumentApproveUpdateClear: CaseReducerType<State, object>;
  companyDocumentApproveUpdateClear: CaseReducerType<State, object>;
};

export const approvedSlice = createSlice<State, CaseReducers<State>>({
  name: "approved",
  initialState,
  reducers: {
    individualDocumentApproveUpdateClear: (state, { payload }) => {
      state.individualDocumentApproveUpdated = initialState.individualDocumentApproveUpdated
    },
    companyDocumentApproveUpdateClear: (state, { payload }) => {
      state.companyDocumentApproveUpdated = initialState.companyDocumentApproveUpdated
    },
  },
  extraReducers: (builder) => {
    builder.addCase(individualDocumentApproveUpdate.fulfilled, (state, action) => {
      state.individualDocumentApproveUpdated = {name: action.payload.name, data: action.payload.data};
    });
    builder.addCase(companyDocumentApproveUpdate.fulfilled, (state, action) => {
      state.companyDocumentApproveUpdated = {name: action.payload.name, data: action.payload.data};
    });
  }
});

export const { companyDocumentApproveUpdateClear, individualDocumentApproveUpdateClear } = approvedSlice.actions;
export default approvedSlice.reducer;
