import { generateColorPalettes } from '@ant-design/compatible/es/theme/default'
import { ThemeConfig } from 'antd'
import { themeColor, themeSize, themeWeight } from './themeStyles'

export const antdTheme: ThemeConfig = {
	token: {
		fontFamily: "Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'",
		colorPrimary: themeColor.primaryColor,
		colorLink: 'inherit',
		colorLinkHover: generateColorPalettes(themeColor.primaryColor)[5],
		colorLinkActive: generateColorPalettes(themeColor.primaryColor)[5],
		colorSuccess: themeColor.green,
		borderRadius: 2,
		fontSizeSM: 12,
	},
	components:{
		Layout:{
			colorBgBody: '#fff',
			colorBgHeader:'#fff',
			fontSizeHeading5: 18,//themeSize.heading5,
		},
		Button: {
			fontWeightStrong: themeWeight.extraBold,
			fontSize: 12
		},
	},
}