import { createSlice } from "@reduxjs/toolkit";
import { Components } from "../../../api/kyc-backend/generated/client";
import { adminMessagesGet } from "../asyncThunks/adminMessagesGet";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";
import { reduce } from "lodash";

export type MessagesResponse = Components.Schemas.AdminInternalMessageInbox;

type State = {
  messages: MessagesResponse
};

const initialState: State = {
  messages: {companyMessages: [], individualMessages: []}
};

type CaseReducers<State> = {
  adminMessagesGetClear: CaseReducerType<State, object>;
};

export const adminInboxSlice = createSlice<State, CaseReducers<State>>({
  name: "adminInbox",
  initialState,
  reducers: {
    adminMessagesGetClear: (state, { payload }) => {
      state.messages = initialState.messages
    }
  },
  extraReducers: (builder) => {
    builder.addCase(adminMessagesGet.fulfilled, (state, action) => {
      state.messages = action.payload;
    })
  },
});

export const { adminMessagesGetClear } = adminInboxSlice.actions;
export default adminInboxSlice.reducer;
