import { createSlice } from "@reduxjs/toolkit";
import { CaseReducerType } from "../../store";

type State = {
  isInternetConnection: boolean;
};

const initialState: State = {
  isInternetConnection: true,
};

type CaseReducers<State> = {
  setInternetConnection: CaseReducerType<State, boolean>;
};

export const deviceSlice = createSlice<State, CaseReducers<State>>({
  name: "device",
  initialState,
  reducers: {
    setInternetConnection: (state, { payload }) => {
      state.isInternetConnection = payload;
    },
  },
});
export const { setInternetConnection } = deviceSlice.actions;
export default deviceSlice.reducer;
