import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type MessagesResponse = Components.Schemas.CompanyInternalMessageEntry;
type PathParameters = Paths.CompanyInternalMessages.PathParameters;

export const companyBackMessagesGet: AsyncThunkActionType<
  MessagesResponse[],
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("companyBackMessagesGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.CompanyInternalMessages(data.params, null, data?.config);
    return response.data as MessagesResponse[];
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
