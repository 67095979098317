import { useEffect, FC } from 'react'
import { Outlet } from 'react-router-dom'
import { useActionStatus } from 'hooks/useActionStatus'
import { useKeycloakRefreshToken, useIsUserLogged } from 'modules/keycloak'
import { CoverPage } from 'modules/kyc-front/ui-elements/CoverPage'
import { useSelector } from 'hooks/useSelector'
import { dispatch } from 'store/store'
import { adminProfileGet } from 'store/kyc-backend/asyncThunks/adminProfileGet'
import { setEnvs } from 'store/kyc-front/slices/envsSlice'
import ScrollToTop from 'utilities/ScrollToTop'
import { logout } from 'modules/keycloak'
import {ENV} from "../../index";

interface configProps {
    apiURL?:  string,
	adminSuffix?: string,
	token?: string,
	profileId: string,
	type: string,
	env?: string,
	accountType: 'PRIVATE' | 'BUSINESS' | '',
	routeSuffix: string
}

const KycKeycloakBack: FC = () => {
	let config: configProps = {
		apiURL: ENV.API_URL,
		env: ENV.ENV,
		profileId: '',
		accountType: '',
		type: 'standalone',
		routeSuffix: '/admin'
	}
	useKeycloakRefreshToken({
		keycloakDomain: ENV.KEYCLOAK_DOMAIN,
		onRefreshError:  () => dispatch(logout(true)),
        client_id: ENV.KEYCLOAK_CLIENT_BACK,
		is_backend: true
	});
	const identity = useSelector((state) => state.admin.admin.profile);
	const gettingProfileStatus = useActionStatus(adminProfileGet);
	const { isUserLogged } = useIsUserLogged();
	const { env, type, routeSuffix, accountType, apiURL, adminSuffix } = config;
	useEffect(() => {
		if(isUserLogged) {
			if(identity.adminId !== '') {
				dispatch(setEnvs({type, env, profileId: '', admin: true, routeSuffix, accountType, apiURL, adminSuffix: '/admin'}));
			} else {
				if(!gettingProfileStatus.isPending && !gettingProfileStatus.isFullfilled) {
					dispatch(adminProfileGet({params: null}));
				}
			}
		}
	}, [identity, isUserLogged]);
	return (identity.adminId !== '' ? <><ScrollToTop /><Outlet /></> : isUserLogged ? <CoverPage visible={true} /> : <Outlet /> )
}

export default KycKeycloakBack