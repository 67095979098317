import { createAsyncThunk } from "@reduxjs/toolkit";
import { api2 } from "api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { UserResponse } from "../slices/userSlice";
import { Components, Paths } from "../../../api/kyc/generated/client2";

type CreateUserRequest = Paths.RegisterUser.RequestBody;

const filterSerializable = (data: any) => {
  if (typeof data !== 'object' || data === null) return data;
  const { config, request, ...serializableData } = data;
  return serializableData;
};

export const userCreate: AsyncThunkActionType<
UserResponse,
  AsyncThunkDataType<null, CreateUserRequest, "data">
> = createAsyncThunk(
  "userCreate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
        const response = await api2.registerUser(params, data, config);
        //@ts-ignore
        return filterSerializable(response) as UserResponse;
    } catch (err: any) {
        return rejectWithValue({data: err.response.data, status: err.response.status});
    }
  }
);