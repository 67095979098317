import { FC } from 'react'
import { Row, Col, Select, Space } from 'antd'
import { moment } from 'modules/kyc-front/ui-elements/FormDate'
import { countries } from 'countries-list'
import FormInput from 'modules/kyc-front/ui-elements/FormInput'
import FormSelect from 'modules/kyc-front/ui-elements/FormSelect'
import FormDate from 'modules/kyc-front/ui-elements/FormDate'
import { dispatch } from 'store/store'
import { kycApplicationSubSectionDelete } from 'store/kyc-front/asyncThunks/kycApplicationSubSectionDelete'
import { kycApplicationSectionDelete } from 'store/kyc-front/asyncThunks/kycApplicationSectionDelete'
import { useSelector } from 'hooks/useSelector'

interface KProps {
    id: string,
    person: any,
    type: 'authorized-persons' | 'ubos' | 'company' | 'individual',
    values: any,
    errors: any,
    handleInputChange: any
    onSelectChange: any,
    onDateChange: any,
    disabled?: boolean,
    printRender?: boolean,
    showRoleType?: boolean,
}


const KycPersonalDetailsForm: FC<KProps> = ({id, person, type, values, errors, handleInputChange, onSelectChange, onDateChange, disabled, printRender, showRoleType}) => {
        const dateFormat = 'YYYY-MM-DD';
        const envs = useSelector(state => state.general.envs)
        const { Option } = Select;
        const personIsArchived = person?.archivedAt ? true : false;
        //const namePrefix = 'authorizedPersons.' + envs.profileId + '.';
        const namePrefix = '';
        return (
            <Space direction='vertical' size={'large'}>
                <Row gutter={30}>
                   <Col span={12}>
                       <FormInput label='Name *' name='firstName' error={errors.firstName} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.firstName} personId={envs.profileId} type={type} showApprove={!personIsArchived} />
                   </Col>
                   <Col span={12}>
                       <FormInput label='Last name *' name='lastName' error={errors.lastName} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.lastName} personId={envs.profileId} type={type} showApprove={!personIsArchived} />
                   </Col>
                </Row>
                {showRoleType &&
                    <Row gutter={30}>
                        <Col span={24}>
                            <FormSelect defaultValue={values.roleType} label='Role' id='roleType' error={errors?.roleType}  placeholder='Select role' onChange={(val) => onSelectChange(val, 'roleType')} disabled={disabled} personId={envs.profileId} type={type} hideApprove={personIsArchived} >    
                                <Option value='Director'>Director</Option>
                                <Option value='OtherIndividual'>Other</Option>
                            </FormSelect>
                        </Col>
                    </Row>
                }
                <Row gutter={30}>
                   <Col span={12}>
                       <FormDate label='Date of birth *' id='dateOfBirth' error={errors.dateOfBirth} defaultPickerValue={moment('1980-01-10', dateFormat)} defaultValue={values.dateOfBirth && moment(values.dateOfBirth, dateFormat)} onChange={(val) => onDateChange(val, 'dateOfBirth')} disabled={disabled} personId={envs.profileId} type={type} hideApprove={personIsArchived} />
                   </Col>
                    <Col span={12}>
                        <FormInput label='Place of birth *' name='placeOfBirth' error={errors.placeOfBirth} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.placeOfBirth} personId={envs.profileId} type={type} showApprove={!personIsArchived} />
                    </Col>
                </Row>
                <Row gutter={30}>
                    <Col span={12}>
                        <FormSelect mode="multiple" defaultValue={(values.nationality && values.nationality  !== '') ? values.nationality.split(',') : values.nationality} label='Nationality/Nationalities *' id='nationality' error={errors.nationality} placeholder='Select nationality' onChange={(val) => onSelectChange(val, 'nationality')} disabled={disabled} personId={envs.profileId} type={type} hideApprove={personIsArchived} >
                            {Object.keys(countries).map((val, i) => {
                                const country = countries[val as keyof typeof countries]
                                return <Option key={'nationality' + i} value={val}>{country.name}</Option>
                            })}
                        </FormSelect>
                    </Col>
                </Row>
                <FormInput label='Current occupation * (Please provide specific information)' name='occupation' error={errors.occupation} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.occupation} type={type} personId={envs.profileId} showApprove={!personIsArchived} />
                            {/*<button onClick={handleDeleteClick}>Delete</button>*/}
            </Space>
        )

}

export default KycPersonalDetailsForm