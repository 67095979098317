import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { companyMessagesGet } from "../asyncThunks/companyMessagesGet";
import { companyMessageViewedPut } from "../asyncThunks/companyMessageViewedPut";
import { individualMessagesGet } from "../asyncThunks/individualMessagesGet";
import { individualMessageViewedPut } from "../asyncThunks/individualMessageViewedPut";
import { companyMessagePost } from "../asyncThunks/companyMessagePost";
import { individualMessagePost } from "../asyncThunks/individualMessagePost";
import { companyMessageResponsePost } from "../asyncThunks/companyMessageResponsePost";
import { individualMessageResponsePost } from "../asyncThunks/individualMessageResponsePost";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";
import { reduce } from "lodash";

type MessageResponse = Components.Schemas.CompanyInternalMessageEntry[];
type MessageReadResponse = Paths.CompanyInternalMessageViewed.Responses.$200;
type IndividualMessageResponse = Components.Schemas.IndividualInternalMessageEntry[];
type IndividualMessageReadResponse = Paths.IndividualInternalMessageViewed.Responses.$200;
type MessageSentResponse = Components.Schemas.CompanyInternalMessageEntry[];
type IndividualMessageSentResponse = Components.Schemas.IndividualInternalMessageEntry[];

type State = {
  companyMessages: MessageResponse,
  companyMessageRead: {id: string, read: MessageReadResponse},
  individualMessages: IndividualMessageResponse,
  individualMessageRead: {id: string, read: IndividualMessageReadResponse},
  companyMessageSent: MessageSentResponse,
  individualMessageSent: IndividualMessageSentResponse,
  companyMessageResponseSent: MessageSentResponse,
  individualMessageResponseSent: IndividualMessageSentResponse
};

const initialState: State = {
  companyMessages: [],
  companyMessageRead: {id: '', read: -1},
  individualMessages: [],
  individualMessageRead: {id: '', read: -1},
  companyMessageSent: [],
  individualMessageSent: [],
  companyMessageResponseSent: [],
  individualMessageResponseSent: []
};

type CaseReducers<State> = {
  companyMessageViewedPutClear: CaseReducerType<State, object>;
  individualMessageViewedPutClear: CaseReducerType<State, object>;
  companyMessagePostClear: CaseReducerType<State, object>;
  individualMessagePostClear: CaseReducerType<State, object>;
  companyMessageResponsePostClear: CaseReducerType<State, object>;
  individualMessageResponsePostClear: CaseReducerType<State, object>;
  companyMessagesGetClear: CaseReducerType<State, object>;
  individualMessagesGetClear: CaseReducerType<State, object>;
};

export const messagesSlice = createSlice<State, CaseReducers<State>>({
  name: "messages",
  initialState,
  reducers: {
    companyMessageViewedPutClear: (state, { payload }) => {
      state.companyMessageRead = initialState.companyMessageRead
    },
    individualMessageViewedPutClear: (state, { payload }) => {
      state.individualMessageRead = initialState.individualMessageRead
    },
    companyMessagePostClear: (state, { payload }) => {
      state.companyMessageSent = initialState.companyMessageSent
    },
    individualMessagePostClear: (state, { payload }) => {
      state.individualMessageSent = initialState.individualMessageSent
    },
    companyMessageResponsePostClear: (state, { payload }) => {
      state.companyMessageResponseSent = initialState.companyMessageResponseSent
    },
    individualMessageResponsePostClear: (state, { payload }) => {
      state.individualMessageResponseSent = initialState.individualMessageResponseSent
    },
    companyMessagesGetClear: (state, { payload }) => {
      state.companyMessages = initialState.companyMessages
    },
    individualMessagesGetClear: (state, { payload }) => {
      state.individualMessages = initialState.individualMessages
    },
  },
  extraReducers: (builder) => {
    builder.addCase(companyMessagesGet.fulfilled, (state, action) => {
      state.companyMessages = action.payload;
    });
    builder.addCase(individualMessagesGet.fulfilled, (state, action) => {
      state.individualMessages = action.payload;
    });
    builder.addCase(companyMessageViewedPut.fulfilled, (state, action) => {
      state.companyMessageRead = action.payload;
    });
    builder.addCase(individualMessageViewedPut.fulfilled, (state, action) => {
      state.individualMessageRead = action.payload;
    });
    builder.addCase(companyMessagePost.fulfilled, (state, action) => {
      state.companyMessageSent = action.payload;
    });
    builder.addCase(individualMessagePost.fulfilled, (state, action) => {
      state.individualMessageSent = action.payload
    });
    builder.addCase(companyMessageResponsePost.fulfilled, (state, action) => {
      state.companyMessageResponseSent = action.payload;
    });
    builder.addCase(individualMessageResponsePost.fulfilled, (state, action) => {
      state.individualMessageResponseSent = action.payload
    });
  },
});

export const { companyMessageViewedPutClear, individualMessageViewedPutClear, companyMessagePostClear, individualMessagePostClear, companyMessagesGetClear, individualMessagesGetClear } = messagesSlice.actions;
export default messagesSlice.reducer;
