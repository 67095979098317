import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "api/kyc/generated/client2";
import { api2 } from "api/kyc/api";

type RequestBody = Components.Schemas.CreateBusinessProfileRequest;

type PathParameters =  Paths.SetProfileComments.PathParameters;

type CreatedResponse = Components.Schemas.CreateBusinessProfileResponse


export const businessProfileCreate: AsyncThunkActionType<
    CreatedResponse,
    AsyncThunkDataType<PathParameters, RequestBody, "params">
> = createAsyncThunk(
  "businessProfileCreate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api2.createBusinessProfile(null, data, config);
      return response.data as CreatedResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
