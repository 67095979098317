import _ from 'lodash'
import {  useNavigate } from 'react-router-dom'
import { FC, useEffect, useState } from 'react'

import { useSelector } from '../hooks/useSelector'
import { dispatch } from '../store/store'

import { setCurrentEditedForm, setEditedFormConfirmed, setFormUpdated } from 'store/kyc-front/slices/navSlice'
import { kycApplicationUpdate } from 'store/kyc-front/asyncThunks/kycApplicationUpdate'
import { kycApplicationSectionUpdate } from 'store/kyc-front/asyncThunks/kycApplicationSectionUpdate'
import { useActionStatus } from 'hooks/useActionStatus'
import { NavItem } from 'modules/kyc-front/kyc-layout/KycLayout'


export const FormSaver: FC = () => {
    const [autoSaving, setAutoSaving] = useState(false)
    const nav = useSelector((state) => state.general.nav);
    let {formUpdated, currentEditedForm, editedConfirm} = nav;
    const { values, type } = currentEditedForm;
    const { onConfirm, onConfirmLink, general } =  editedConfirm;
    const envs = useSelector((state) => state.general.envs);
    let apUboID = editedConfirm.refId 
    let navigate = useNavigate();
    let isStandaloneAdmin = envs.admin && envs.type === 'standalone'
    let dispatchSubmit: any = kycApplicationUpdate
    let params: any = {id: envs.profileId}
    if(type === 'authorized-persons' || type === 'ubos') {
        dispatchSubmit = kycApplicationSectionUpdate
        params = {applicationId: envs.profileId, sectionName: type, sectionId: apUboID}
    }
    const {isFullfilled, isError} = useActionStatus(dispatchSubmit)
    const adminPrefix = general ? envs.routeSuffix : `${envs.routeSuffix}/${NavItem.CLIENT_PROFILES}/${envs.profileId}` 
    const linkPath = isStandaloneAdmin ? general === true ? envs.routeSuffix + onConfirmLink : adminPrefix + onConfirmLink : general === true ? envs.routeSuffix + onConfirmLink : envs.routeSuffix + '/' + NavItem.CLIENT_PROFILE + onConfirmLink
    useEffect(() => {
        if(onConfirm === true) {
            if(!_.isEmpty(values)) {
                confirm();
            } else {
                dispatch(setCurrentEditedForm({}))
                dispatch(setFormUpdated({updated: false, form: []}))
                dispatch(setEditedFormConfirmed({}))
                navigate(linkPath)
            }
        }
    }, [onConfirm, onConfirmLink]);
    useEffect(() => {
        if(autoSaving === true) {
            if(isFullfilled) {
                dispatch(setCurrentEditedForm({}))
                dispatch(setFormUpdated({updated: false, form: []}))
                if(editedConfirm.showErrors !== true) {
                    dispatch(setEditedFormConfirmed({}))
                } else {
                    dispatch(setEditedFormConfirmed({showErrors: true}))
                }
                setAutoSaving(false)
                if(onConfirmLink && onConfirmLink !== '') {
                    navigate(linkPath)
                }
                
            }
            if(isError) {
                dispatch(setCurrentEditedForm({}))
                dispatch(setFormUpdated({updated: false, form: []}))
                dispatch(setEditedFormConfirmed({}))
                setAutoSaving(false)
            }
        }

    }, [isFullfilled, isError])
    const confirm = () => {
        let transformBools = true;
        if(formUpdated.updated && !_.isEmpty(values)) {
            let submitValues = {...values};
            if(transformBools) {
                Object.entries(submitValues).forEach(([i, value]) => {
                    if(value === 'true') {
                        submitValues[i as keyof typeof submitValues] = true;
                    }
                    if(value === 'false') {
                        submitValues[i as keyof typeof submitValues] = false;
                    }
                });
            }
            if(dispatchSubmit) {
                setAutoSaving(true)
                dispatch(
                    dispatchSubmit({
                        params: params,
                        data: submitValues
                    }),
                )
                
            } 
            
            
        } else {
            dispatch(setCurrentEditedForm({}))
            dispatch(setFormUpdated({updated: false, form: []}))
            dispatch(setEditedFormConfirmed({}))
            if(onConfirmLink && onConfirmLink !== '') {
                navigate(linkPath)
            }
        }
        
    }
    return <></>
}