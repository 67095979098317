import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";
import { companyArchive } from "../asyncThunks/companyArchive";
import { individualArchive } from "../asyncThunks/individualArchive";
import { ObjectKeyType } from "types";
import { reduce } from "lodash";
import { CaseReducerType } from "../../store";

export type CompanyResponse = Paths.ArchiveCompanyToggle.Responses.$200;
export type IndividualResponse = Paths.ArchiveIndividualToggle.Responses.$200;

type State = {
    companyArchived: CompanyResponse;
    individualArchived: IndividualResponse;
};


const initialState: State = {
    companyArchived: false,
    individualArchived: false
};

type CaseReducers<State> = {
  companyArchiveClear: CaseReducerType<State, object>;
  individualArchiveClear: CaseReducerType<State, object>;
};

export const archivedSlice = createSlice<State, CaseReducers<State>>({
  name: "archived",
  initialState,
  reducers: {
    companyArchiveClear: (state, { payload }) => {
        state.companyArchived = initialState.companyArchived
    },
    individualArchiveClear: (state, { payload }) => {
        state.individualArchived = initialState.individualArchived
    }
  },
  extraReducers: (builder) => {
    builder.addCase(companyArchive.fulfilled, (state, action) => {
        state.companyArchived = action.payload
    });
     builder.addCase(individualArchive.fulfilled, (state, action) => {
        state.individualArchived = action.payload
    });
  },
});

export const { companyArchiveClear, individualArchiveClear } = archivedSlice.actions;
export default archivedSlice.reducer;
