import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Paths } from "api/kyc/generated/client2";
import { api2 } from "api/kyc/api";
import { ApprovedResponse } from "../slices/businessProfileSlice";

type PathParameters = Paths.ApproveProfile.PathParameters
type BodyData = Paths.ApproveProfile.RequestBody


export const businessProfileApprove: AsyncThunkActionType<
    ApprovedResponse,
    AsyncThunkDataType<PathParameters, BodyData, "params">
> = createAsyncThunk(
  "businessProfileApprove",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api2.approveProfile(params, data, config);
      return response.status as ApprovedResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
