import Title from 'antd/es/typography/Title'
import { Space } from 'antd'
import { FC } from 'react'
import styled from 'styled-components'
import { themeSize } from '../../styles/themeStyles'
import { useSelector } from '../../hooks/useSelector'

const LowLevelTitle = styled(Title)`
	margin-bottom: 0!important;
	font-size: ${themeSize.base}!important
`

const CustomList = styled.ul`
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
        font-size: 13px;
        margin-top: 10px;
    }
`

const CustomSubList = styled.ul`
    list-style: none;
    padding-left: 10px;
    li {
    	margin-top: 5px;
    }
`

const Descriptions = [
	{'type': 'ALL', 'category': 'passport', 'description': 'All passport certifications must state the following “I confirm that this document is a true copy of the original document and the photo is a true likeness of ________, seen and verified by me”'},
	{'type': 'ALL', 'category': 'passport', 'description': 'Certifications of documents can be made by notaries, lawyers, certified public accounts or government authorities that usually issue such certifications in the respective country and must state the name of the certifier, professional organization he/she is a member of or government authority he/she works for, and date of certification.'},
	{'type': 'ALL', 'category': 'all', 'description': 'Name and address of the applicant mentioned on the KYC form, should match with the documentary proof submitted.'},
	{'type': 'BUSINESS', 'category': 'address', 'description': 'If correspondence and permanent address are different, then proofs for both have to be submitted.'},
	{'type': 'ALL', 'category': 'address', 'description': 'List of documents admissible as proof of address:', 'subList': <CustomSubList><li>a. Utility bills like fixed telephone bill, water and electricity bill (not more than 12 months old)</li><li>b. Bank account statements (not more than 12 months old)</li><li>c. Identity card/document with address issued by a government authority</li></CustomSubList>}
]

const KycDocsInfo: FC = () => {
	const envs = useSelector((state) => state.general.envs);
    const { accountType } = envs;
    const filteredDescriptions = Descriptions.filter(el => el.type === accountType || el.type === 'ALL')
	return (
		<Space size={30} direction='vertical'>
			<LowLevelTitle >Documents & Certification</LowLevelTitle>
			<CustomList>
				{filteredDescriptions.map((description, i) => <li key={i}>{i + 1}. {description.description} {description.subList && description.subList}</li>)}
			</CustomList>
		</Space>
			 
	)
}

export const KycPassportInfo: FC = () => {
	const envs = useSelector((state) => state.general.envs);
    const { accountType } = envs;
    const filteredDescriptions = Descriptions.filter(el => (el.type === accountType || el.type === 'ALL') && (el.category === 'passport' || el.category === 'all'))
	return (
		<CustomList>
			{filteredDescriptions.map((description, i) => <li key={i}>{i + 1}. {description.description} {description.subList && description.subList}</li>)}
		</CustomList>
			 
	)
}

export const KycAddressInfo: FC = () => {
	const envs = useSelector((state) => state.general.envs);
    const { accountType } = envs;
    const filteredDescriptions = Descriptions.filter(el => (el.type === accountType || el.type === 'ALL') && (el.category === 'address' || el.category === 'all'))
	return (
		<CustomList>
			{filteredDescriptions.map((description, i) => <li key={i}>{i + 1}. {description.description} {description.subList && description.subList}</li>)}
		</CustomList>
			 
	)
}


export default KycDocsInfo

