import React from 'react'
import {
  AsyncThunk,
  CaseReducer,
  configureStore,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistCombineReducers,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { AsyncThunkDataType } from "./common";
import slices from "./slices";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: [],
  blacklist: [],
};

const persistedReducer = persistCombineReducers(persistConfig, slices);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      //serializableCheck: false
    }),
});

export const persistor = persistStore(store);
export const dispatch = store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunkType = {
  dispatch: AppDispatch;
  state: RootState;
  rejectValue: unknown; // use type guard in components to detect type of data
  extra?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};
/*
	Use it as type for createAsyncThunk	eg:
	const myAction: AsyncThunkActionType<..> = createAsyncThunk(..)
 */
export type AsyncThunkActionType<
  Returned,
  ThunkArg = AsyncThunkDataType
> = AsyncThunk<Draft<Returned>, ThunkArg, AppThunkType>;
/*
	Use it to create new reducer(action) in createSlice()
 */
export type CaseReducerType<State, PayloadState = void> = CaseReducer<
  State,
  PayloadAction<PayloadState>
>;


