import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "../../store"
import { AsyncThunkDataType } from "../../common"
import { Components, Paths } from "api/kyc/generated/client2"

type PathParameters = Paths.DownloadDocument.PathParameters

type ProfileResponse = string

export const kycApplicationDocGet: AsyncThunkActionType<
  ProfileResponse,
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("kycApplicationDocGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    let response = await api2.downloadDocument(data.params, null, data?.config);
    return response.data as ProfileResponse;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});