import { Modal } from "antd"
import TextArea from 'antd/es/input/TextArea'
import { FC, useState } from "react"

type SProps = {
	open: boolean,
	handleOk: (value: any) => void,
	handleCancel: (value: any) => void
}

const SendBackModal: FC<SProps> = ({open, handleOk, handleCancel}) => {
	const [description, setDescription] = useState('')
	const handleDescriptionChange = (el: any) => {
		setDescription(el.target.value)
	}
	return (
		<Modal
			title="Send back to Client"
			open={open}
			onOk={() => handleOk(description)}
			okText='Send'
			onCancel={handleCancel}
		>
			<br />
			<label>Provide clarification which will be sent to the client (optional)</label>
			<TextArea style={{resize: "none", marginTop: 5, marginBottom: 15}} onChange={handleDescriptionChange} rows={5} value={description}></TextArea>
			<br />
      </Modal>
	)
}

export default SendBackModal