import { Col } from 'antd'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'

interface CProps {
	children: ReactNode
}


const ColumnRightAlign = styled(Col)`
	text-align: right
`


export const ColRight: FC<CProps> = ({children}) => <ColumnRightAlign md={12}>{children}</ColumnRightAlign>

