export const descriptions = {
	beneficialOwners: 'I hereby confirm, that I am the sole beneficial owner of all assets brought in to this business relationship. I am the contracting party, I am not acting on behalf of a third party and I will not share my access to Bitclear AG’s platform with anyone else.',
	beneficialOwnersUbo1: 'The above person has been identified being the beneficial owner as referred to in article 3, paragraph 1 (a) DDO.',
	beneficialOwnersUbo2: 'Please only tick ONE of below options, starting with the first. If the first does not apply, go to the second.  If the second does not apply go to the third. If the third does not apply, another person has to be declared Beneficial Owner.',
	roleTypeHoldsMoreThan25PercentageOfShares:'A natural person who ultimately directly or indirectly holds or controls a share or voting rights amounting to 25% or more of that legal entity or receives 25% or more of the profits of that legal entity.',
	roleTypeUltimatelyControlOverBusiness:'A natural person who ultimately in another way exercises control over the business management of that legal entity.',
	roleTypeMemberOfGoverningBody:'A natural person who is a member of the governing body if - after exhausting all possibilities and provided there is no suspicion - no person mentioned above has been determined.',
	sourceOfWealthUbo: 'The wealth of above Beneficial Owner is not derived from any illegal activities but is derived from',
	sourceOfWealth: 'My wealth is not derived from any illegal activities but is derived from',
	estimatedExchangeAmountFuture: 'Estimated amount of future exchange transactions settled through Bitclear AG per year',
	taxCompliance: 'I also recognize that the Beneficial Owner may be subject to tax where tax resident and hereby undertakes to obtain advice as necessary to ensure that the due taxes are paid and statutory compliance is honored. To this effect, Bitclear AG accepts no liability for any tax consequences.',
	nonPoliticallyExposedPerson: 'Above Beneficial Owner does not fall within the definition of ‘politically exposed person’',
	politicallyExposedPerson: 'Above Beneficial Owner does fall within the definition of ‘politically exposed person‘',
	// explanation is continuation of 'nonPoliticallyExposedPerson' label in form, and continuation for both labels in pdf
	politicallyExposedPersonExplanation: ', being natural persons who are or have been entrusted with a prominent public function within the last year, including their immediate family members or others known to be close associates of such a person, but shall not include middle ranking or more junior officials.',
	nonUSNationality: 'Above Beneficial Owner does not fall within the definition of a ‘US Person’',
	uSNationality: 'Above Beneficial Owner does fall within the definition of a ‘US Person’',
	// explanation is continuation of 'nonUSNationality' label in form, and continuation for both labels in pdf
	uSNationalityExplanation: ', being a natural person who is citizen or resident of the United States of America, has any other type of mailing address or residence permit (green card) or was born in the United States of America.',
	correctness1: 'Intentionally providing false information in this form is a criminal offense under the Liechtenstein Criminal Code. Any changes must be communicated to Bitclear without delay.',
	correctness2: 'I hereby declare that the details furnished above are true and correct to the best of my knowledge and belief.',
	correctness3: 'Further, I undertake to inform Bitclear of any changes immediately, by submitting information regarding changes on the KYC Platform (kyc.bitclear.com).',
	signatoriesDeclarations1: 'The funds that are and will be used to fund the transactions are clear and are not derived from any illegal activities but are derived from above mentioned source(s).',
	signatoriesDeclarations2: 'We also recognize that the company may be subject to tax where tax resident and we hereby undertake to obtain advice as necessary to ensure that the due taxes are paid and statutory compliance is honored. To this effect, Bitclear AG accepts no liability for any tax consequences.',
	signatoriesDeclarations3_1: 'We hereby declare that the details furnished above are true and correct to the best of our knowledge and belief and',
	signatoriesDeclarations3_2: 'we undertake to inform you of any changes therein, immediately by sending an email to kyc@bitclear.com.',
	signatoriesDeclarations3_3: 'In case any of the above information is found to be false or untrue or misleading or misrepresenting, we are aware that we may be held liable for it.',
	signatoriesDeclarations4: 'The EU General Data Protection Regulation (GDPR), which came into force on 25th May 2018, is effective for all EU and EEA residents in respect of their personal data which is collected and processed. The Privacy Policy statement of Bitclear AG in respect of the processing of clients’ data and the rights of clients is available on www.bitclear.com/privacy-policy. Please contact kyc@bitclear.com for any data protection matters.',
}