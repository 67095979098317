import Title from 'antd/es/typography/Title'
import Text from 'antd/es/typography/Text'
import { Col, Spin } from 'antd'
import { profileGet } from '../../../store/kyc-front/asyncThunks/profileGet'
import { individualGet } from '../../../store/kyc-front/asyncThunks/individualGet'
import { useSelector } from '../../../hooks/useSelector'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { dispatch } from '../../../store/store'
import { FC, useEffect, useRef } from 'react'
import { userDisplayedStatuses } from '../../../utilities/nameParsers'
import styled from 'styled-components'
import { KycDocumentsSteps } from '../KycDocumentsSteps';
import KycNextSteps from '../KycNextSteps'
import { themeColor, themeSize } from '../../../styles/themeStyles'
import KycLayout, { NavItem } from '../kyc-layout/KycLayout'
import KycPrint from '../KycPrint'
import { SuccessIcon, QuestionIcon, ErrorIcon } from '../ui-elements/SuccessInfo'
import Well from '../ui-elements/Well'


const TextMain = styled(Text)`
	font-size: ${themeSize.base};
	line-height: 32px;
`

const TextCustom = styled(Text)`
	&.ant-typography.ant-typography-success {
		color: ${themeColor.green}!important;
	}
`

const KycIntro: FC = () => {
	const profile = useSelector((state) => state.user.profile)
	const individual = useSelector((state) => state.user.individual)
	const envs = useSelector((state) => state.general.envs)
	const { stepsScheme, kycApplication } = useSelector((state) => state.user2.kycApplication)
	const printRef = useRef(null)
  	const { userProfile } = profile
  	const companyGetStatus = useActionStatus(profileGet)
  	const individualGetStatus = useActionStatus(individualGet)
  	const status = kycApplication.status;
  	const approved = status === 'ACCEPTED'
	const clarify = status === 'CLARIFICATION_NEEDED'
	const rejected = status === 'REJECTED'
	const displayedStatus = userDisplayedStatuses(status)
	const review = displayedStatus === 'SUBMITTED'
	const keys = Object.keys(kycApplication.values)
	const nextName = kycApplication.status === 'NEW' && keys.length === 1 && keys[0] === 'id' ? 'Begin your application' : 'Continue'
  	useEffect(() => {
  		if(envs.isBusiness) {
  			dispatch(profileGet({ params: { companyId: envs.profileId} }))
  		} else {
  			dispatch(individualGet())
  		}
    }, []);
	const processMessage = (text: string) => {
		const trimmedText = text.replace(/^"(.*)"$/, '$1')
		return trimmedText.replace(/\\n/g, '\n')
	  };
	return (
		<KycLayout nav={{next: stepsScheme[0].link, step: 0, nextName: nextName, nextButton: "primary", status: status, navPage: NavItem.CLIENT_PROFILE}}>
			{!individual.individual.businessRelation.isActive && !profile?.userProfile?.profile?.businessRelation.isActive && status !== 'NEW' &&
				<Col span={24} style={{'marginBottom': '20px', 'paddingLeft': '0', 'marginTop': '-40px', textAlign: displayedStatus === 'NEW' ? 'left' : 'center'}}>
					{displayedStatus === 'NEW' &&
						<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>Your KYC application is 
							<TextCustom type="secondary"><b> partially filled </b></TextCustom>
						</Text>
					}
					{displayedStatus === 'SUBMITTED' &&
						<>
							<div style={{marginBottom: 40}}>
								<SuccessIcon />
							</div>
							<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>Your application is complete and pending our review</Text>
						</>
					}
				</Col>
			}
			{companyGetStatus.isFullfilled || individualGetStatus.isFullfilled ? (
				<>
					{individual?.individual?.businessRelation.isActive || profile?.userProfile?.profile?.businessRelation.isActive ? (
						<>
							<Col span={24} style={{'marginBottom': '20px', 'paddingLeft': '0'}}>
								<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>Business Relation: 
									<TextCustom type='success'><b> Active </b></TextCustom>
								</Text>
							</Col>
							<Title level={4}>
								Your Bitclear Applications are Active
							</Title>
							<TextMain>
								We've received Your documents and approved them<br />
								All functionalities of Bitclear Applications have been unlocked for You<br />
								<br />
							</TextMain>
							<KycPrint />
						</>
					) : (
						<>
							{review ? (
								<>
									<Title level={4} style={{textAlign: 'center'}}>
										We will contact you as soon as it is done
									</Title>
									
									<KycNextSteps />
								</>
							) : (
								<>
									{approved ? (
										<>
											<div style={{marginBottom: 40}}>
												<SuccessIcon />
											</div>
											<Title level={4} style={{textAlign: 'center'}}>
												Your KYC is approved and ready.
											</Title>
											<TextMain>
												If you have not already done so, download the approved KYC, print it, sign it and send it along with <b>all the uploaded documents</b> by post or courier to the following address:<br />
												<br />
												Bitclear Aktiengesellschaft<br />
												PO Box 534<br />
												Industriestrasse 26<br />
												9491 Ruggell, Liechtenstein<br />
											</TextMain>
											<KycPrint />
										</>

										) : (
										<>
											{clarify ? (
												<>
													<div style={{marginBottom: 40}}>
														<QuestionIcon />
													</div>
													<Title level={4} style={{textAlign: 'center'}}>
															Your application requires some changes
													</Title>
													<br />
													<label>Admin requested changes in your KYC application: </label>
													<br />
													{kycApplication.message &&
														<>
														<Col>
															<Well>
																<pre style={{marginBottom: 0}}>{processMessage(kycApplication.message)}</pre>
															</Well>
														</Col>
														</>
													}
													<br />
													<label>After changes are done please submit your KYC once again</label>
													<br />
												</>
											) : (
												<>
												{rejected ? (
													<>
														<div style={{marginBottom: 40}}>
															<ErrorIcon />
														</div>
														<Title level={4} style={{textAlign: 'center'}}>
															Your application has been rejected
														</Title>
														<p style={{textAlign: 'center'}}>Please contact us if you have some question</p>
														<br />
													</>

												) : (
													<>
														<div>
															<TextMain>
																To protect our clients we have implemented a strict "Know Your Client" process. In order to go through the process smoothly, please prepare the below-mentioned documents.
															</TextMain>
														</div>
														<KycDocumentsSteps />
														<Title level={5}>Please be aware that you need to send the original documents to Bitclear AG.</Title>
													</>
												)}
												</>
											)}
										</>
									)}
								
								</>
							)}
						</>
					)}
					
				</>
			) : (
				<Spin size='large' />
			)}
		</KycLayout>
			 
	)
}

export default KycIntro
