import { FC } from 'react'
import { Row, Col, Space } from 'antd'
import Text from 'antd/es/typography/Text'
import styled from 'styled-components'
import { themeColor } from 'styles/themeStyles'
import { descriptions } from './common'

const Signature = styled.div`
    border: 1px solid ${themeColor.grayLight};
    padding: 5px 10px;
    height: 100px;
    min-width: 200px;
    margin-top: 15px;
    margin-right: 15px;
    text-align: center;
`

interface KProps {
    person: any,
    printRender: boolean
}

const KycCorrectnessDeclarationForm: FC<KProps> = ({person, printRender}) => {
    return (
        <Space direction='vertical' size={'large'}>
            <div style={!printRender ? {'marginBottom': '15px'} : {}}>
                <Text><b>1. Correctness and changes</b></Text><br/>
                <Text>{descriptions.correctness1}<br />{descriptions.correctness2} <b>{descriptions.correctness3}</b></Text>
            </div>
            {printRender && (
                <>
                    <br />
                    <Text><b>Customer's signature:</b></Text>
                    <Row>
                        <Col><Signature><span>{person.firstName} {person.lastName}</span></Signature></Col>
                    </Row>
                </>
            )}
        </Space>
        )

}

export default KycCorrectnessDeclarationForm