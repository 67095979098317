import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type NotesResponse = Components.Schemas.NoteEntry;
type PathParameters = Paths.CompanyNotes.PathParameters & Paths.CompanyNotes.QueryParameters;

export const companyNotesGet: AsyncThunkActionType<
  NotesResponse[],
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("companyNotesGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.CompanyNotes(data.params, null, data?.config);
    return response.data as NotesResponse[];
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
