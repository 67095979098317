import { usePDF } from '@react-pdf/renderer'
import { noop } from 'lodash'
import { FC, useEffect } from 'react'
import styled from 'styled-components'
import { themeColor } from '../styles/themeStyles'
import { PdfDocument, PdfContentProps } from './PdfDocument'

const ErrorBox = styled.div`
	max-width: 300px;
	margin: auto;
	color: ${themeColor.pink};
`

type IProps = {
	onUrl?: (url: string | null) => void
} & PdfContentProps

export const PdfWrapper: FC<IProps> = ({ onUrl = noop, ...props }) => {
	const [{ error, url }] = usePDF({
		document: <PdfDocument {...props} />,
	})

	useEffect(() => {
		onUrl(url)
	}, [onUrl, url, error])

	return error ? <ErrorBox>An error has occurred. Please try later.</ErrorBox> : null
}

export default PdfWrapper
