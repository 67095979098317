import { FC, useState, useEffect } from 'react'
import _ from 'lodash'
import { Col, Switch, message } from 'antd'
import { useSelector } from '../../../hooks/useSelector'
import { themeColor } from '../../../styles/themeStyles'
import { dispatch } from '../../../store/store'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { companyDocumentApproveUpdateClear, individualDocumentApproveUpdateClear } from '../../../store/kyc-backend/slices/approvedSlice'
import { individualDocumentApproveUpdate } from '../../../store/kyc-backend/asyncThunks/individualDocumentApproveUpdate'
import { companyDocumentApproveUpdate } from '../../../store/kyc-backend/asyncThunks/companyDocumentApproveUpdate'
import { individualBackDocsGet } from '../../../store/kyc-backend/asyncThunks/individualBackDocsGet'
import { companyDocsGet } from '../../../store/kyc-backend/asyncThunks/companyDocsGet'
import styled from 'styled-components'

const Approve = styled(Col)`
    font-size: 13px;
    label {
        color: ${themeColor.gray};
    }
    .small {
        font-size: 12px;
        margin-left: 8px;
    }
    .ant-switch-checked {
        background-color: ${themeColor.green}
    }
    &.document-approve {
        position: relative;
        top: -37px;
        left: 80%;
        left: calc(100% - 120px);
        height: 0;
    }
`

interface IProps {
    onSwitchChange?: (value: any) => void,
    name: string,
    schemaParent?: string,
    schemaName?: string,
    type: 'company' | 'individual' | 'document',
    isDocument?: boolean
    personId?: string
}

const ApproveInput: FC<IProps> = ({
   name, schemaParent, schemaName, type, isDocument
}) => {
    const envs = useSelector((state) => state.general.envs);
    const admin = useSelector((state) => state.admin.admin);
    const [isApproved, setIsApproved] = useState(false);
    const [approvesCount, setApprovesCount] = useState(0);
    const documentsApproves = useSelector((state) => envs.accountType === 'BUSINESS' ? state.admin.backCompanyDocs.companyDocs : state.admin.backIndividualDocs.individualDocs); 
    //documents
    const documentUpdate = useSelector((state) => envs.accountType === 'BUSINESS' ? state.admin.approved.companyDocumentApproveUpdated : state.admin.approved.individualDocumentApproveUpdated);
    const documentStatus = useActionStatus(envs.accountType === 'BUSINESS' ? companyDocumentApproveUpdate : individualDocumentApproveUpdate);
    const click = (el: any) => {
        if(envs.accountType === 'BUSINESS') {
            dispatch(companyDocumentApproveUpdate({params: {companyId: envs.profileId, documentId: name}, data: {name: name, data: {approve: !isApproved}}}))
        } else {
            dispatch(individualDocumentApproveUpdate({params: {individualId: envs.profileId, documentId: name}, data: {name: name, data: {approve: !isApproved}}}))
        }
    }
    useEffect(() => {
        if(documentStatus.isError || documentStatus.isFullfilled) {
            if(documentUpdate?.name === name) {
                if(documentStatus.isError) {
                    message.error('Some error occurred while updating this element'); 
                }
                if(documentStatus.isFullfilled) {
                    message.success('This element was successfully updated');
                    setIsApproved(!isApproved);
                }
                if(envs.accountType === 'BUSINESS') {
                    dispatch(companyDocumentApproveUpdateClear({}));
                    //@ts-ignore
                    dispatch(companyDocsGet({params: {companyId: envs.profileId}}))
                } else {
                    dispatch(individualDocumentApproveUpdateClear({}))
                    dispatch(individualBackDocsGet({params: {individualId: envs.profileId}}));
                }
            }
            
        }
    }, [documentUpdate])
    useEffect(() => {
        documentsApproves.forEach(approveDoc => {
            //@ts-ignore
            if((approveDoc?.documentId === name)) {
                //@ts-ignore
                if(approveDoc.approvedBy.find(el => el === admin.profile.adminId)) {
                    setIsApproved(true)
                }
                //@ts-ignore
                let count = approveDoc.approvedBy.length;
                setApprovesCount(count);
            } else {
                //@ts-ignore
                if(Array.isArray(approveDoc.value)) {
                    //@ts-ignore
                    approveDoc.value.forEach(approveDocInner => {
                        if((approveDocInner?.documentId === name)) {
                            //@ts-ignore
                            if(approveDocInner.approvedBy.find(el => el === admin.profile.adminId)) {
                                setIsApproved(true)
                            } 
                            //@ts-ignore
                            let count = approveDocInner.approvedBy.length;
                            setApprovesCount(count);
                        }
                    })
                }
            }
        }) 
    }, [documentsApproves]);
    return (
        <Approve className={isDocument ? 'document-approve' : ''}>
            {!isDocument && <label>Approved: </label>}
            <Switch onChange={click} size="small" checked={isApproved} />
            <label className='small'>{approvesCount} of 2 </label>
        </Approve>
	)
}

export default ApproveInput