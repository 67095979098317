import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { MessagesResponse } from '../slices/adminInboxSlice'
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

export const adminMessagesGet: AsyncThunkActionType<
  MessagesResponse,
  AsyncThunkDataType<null, null, "params">
> = createAsyncThunk("adminMessagesGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.InternalMessagesInbox(null, null, data?.config);
    return response.data as MessagesResponse;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
