import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Paths } from "api/kyc/generated/client2";
import { api2 } from "api/kyc/api";
import { DeletedResponse } from "../slices/businessProfileSlice";

type PathParameters = Paths.DeleteBusinessProfile.PathParameters


export const businessProfileDelete: AsyncThunkActionType<
    DeletedResponse,
    AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk(
  "businessProfileDelete",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api2.deleteBusinessProfile(params, null, config);
      return response.status as DeletedResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
