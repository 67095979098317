import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "store/store"
import { AsyncThunkDataType } from "store/common"
import { Paths } from "api/kyc/generated/client2"

type PathParameters = Paths.RejectApplication.PathParameters
type ProfileResponse = Paths.RejectApplication.Responses.$200
type RequestBody = Paths.RejectApplication.RequestBody

export const kycApplicationReject: AsyncThunkActionType<
  ProfileResponse,
  AsyncThunkDataType<PathParameters, RequestBody, "params">
> = createAsyncThunk("kycApplicationReject", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI
  try {
    const response = await api2.rejectApplication(data?.params, data?.data, data?.config)
    return response.data as ProfileResponse
  } catch (err: any) {
    return rejectWithValue(err.response.data)
  }
})
