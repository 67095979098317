import Title from 'antd/es/typography/Title'
import * as Yup from 'yup'
import { Row, Col, Divider } from 'antd'
import { useSelector } from '../../../../hooks/useSelector'
import { FC } from 'react'
import ApprovalSignature from '../../ui-elements/ApprovalSignature'
import DirectorsTable from './DirectorsTable'
import BasicFormWrapper from '../../../kyc-front/ui-elements/BasicForm'
import FormInput from '../../../kyc-front/ui-elements/FormInput'
import { DividerFirst } from '../../../kyc-front/ui-elements/Dividers'
import styled from 'styled-components'
import translateCountries from 'utilities/translateCountries'
import translateSoFName from 'utilities/translateSoFName'
import Well from 'modules/kyc-front/ui-elements/Well'
import { businessProfileUpdate } from 'store/kyc-backend/asyncThunks/businessProfileUpdate'
import { businessProfileUpdateClear } from 'store/kyc-backend/slices/businessProfileSlice'
import { businessProfileApprove } from 'store/kyc-backend/asyncThunks/businessProfileApprove'
import { Components } from 'api/kyc/generated/client2'

interface BProps {
    printRender: boolean,
    initialValues: any,
    disabled: boolean,
    snapshotId: string,
    bPData: any
}

interface CProps {
    isV1: boolean, 
    isApproved: boolean, 
    name: string,
    value: string, 
    label: string, 
    showApprove: boolean, 
    error: string, 
    handleInputChange: any, 
    isEditable: boolean
}

const Summary = styled.p`
	span {
		//width: 120px;
		font-size: 16px;
		display: inline-block;
		&.value {
			width: auto;
			display: inline-block;
			margin-left: 5px;
		}
	}
`
const WellCustom = styled(Well)`
    &.approved {
        padding-top: 5px;
        padding-bottom: 5px;
        p {
            margin-bottom: 5px;
        }
    }
`

const FormInputCustom = styled(FormInput)`
    .ant-form-item-label div, .input-textarea {
        font-size: 16px;
    }
    .input-textarea {
        margin-bottom: 15px;
    }
    
`
const BusinessProfileComment: FC<CProps> = ({isV1, isApproved, value, label, showApprove, error, handleInputChange, isEditable, name}) => (
    <>
        {isV1 ? (<></>) : (
            <WellCustom className={isApproved ? 'approved' : ''}>
                {isApproved ? (
                    <div style={{marginTop: 5}}>
                        <Summary>
                            <span>{label}</span>
                            <span className='value'><b>{value}</b></span>
                        </Summary>
                    </div> 
                ) : (
                    <FormInputCustom showApprove={showApprove} label={label} name={name} error={error} component='textarea' onChange={handleInputChange} disabled={!isEditable} value={value} printRender={false} type='company' />
                )}
            </WellCustom>
        )}
    </>
)

const BusinessProfileForm: FC<BProps> = ({printRender, initialValues, bPData, snapshotId, children}) => {
	const envs = useSelector((state) => state.general.envs);
	const isBusiness = envs.accountType === 'BUSINESS';
    const { businessProfileUpdated } = useSelector((state) => state.admin2.businessProfiles)
	const adminProfile = useSelector((state) => state.admin.admin);
	const validation = Yup.object({});
    const isV1 = bPData.formVersion === 'V1'
    const data = bPData.data
    const isEditable = bPData.status === 'DRAFT' && !isV1
    const isApproved = bPData.status === 'APPROVED'
    const showApprove = false
    return (
        <BasicFormWrapper 
            id='businessProfileForm' 
            successCondition={businessProfileUpdated}
            responseState={bPData}
            validation={validation}
            initial={initialValues} 
            printRender={false} 
            saveText='Update profile'
            saveDraft={true}
            saveDraftPopup={bPData?.clientAdvisorApproval?.approvedBy || bPData?.complianceOfficerApproval?.approvedBy}
            standaloneFormSubmit={true}
            editable={false}
            dispatchSubmit={businessProfileUpdate}
            dispatchClear={businessProfileUpdateClear}
            //@ts-ignore
            params={{businessProfileId: snapshotId}}
            hideOnDisabled={false}
        >
            {({ handleInputChange, values, errors }) => {
                const commentProps = {
                    isV1,
                    isApproved,
                    showApprove,
                    handleInputChange, 
                    isEditable
                }
                return (
                    <>
                        <DividerFirst />
                        <Title level={3}>Basic {isBusiness ? 'Company ' : ''}Information</Title>
                        <Row>
                            <Col span={24}>
                                <Summary>
                                    <span>{isBusiness ? 'Name of legal entity ' : 'Client name'}:</span>
                                    <span className='value'><b>{data?.basicInformation?.name}</b></span>
                                </Summary>
                                {isBusiness ? (
                                    <>
                                        <Summary>
                                            <span>Legal form:</span>
                                            <span className='value'><b>{data?.basicInformation?.legalForm}</b></span>
                                        </Summary>
                                        {isV1 ? (
                                            <Summary>
                                                <span>Public key (mandatory for VT services):</span>
                                                <span className='value'><b>{data?.basicInformation?.publicKey}</b></span>
                                            </Summary>
                                        ) : (<></>)}
                                    </>
                                ) : (<></>)}
                                <BusinessProfileComment {...commentProps} name='basicInformationComments' label={`Basic ${isBusiness ? 'Company ' : ''}Information - Bitclear comment:`} value={values.basicInformationComments} error={errors.basicInformationComments} />
                            </Col>
                        </Row>
                        <Divider />
                        <Title level={3} className='title4'>{isBusiness ? 'Registered address' : 'Address'}</Title>
                        <Row>
                            <Col span={isBusiness ? 12 : 24}>
                                <Summary>
                                    <span>Address:</span>
                                    <span className='value'><b>{data?.basicInformation?.address}</b></span>
                                </Summary>
                                <Summary>
                                    <span>Postcode:</span>
                                    <span className='value'><b>{data?.basicInformation?.postcode}</b></span>
                                </Summary>
                                <Summary>
                                    <span>City:</span>
                                    <span className='value'><b>{data?.basicInformation?.city}</b></span>
                                </Summary>
                                <Summary>
                                    <span>Country:</span>
                                    <span className='value'><b>{translateCountries(data?.basicInformation?.country)}</b></span>
                                </Summary>
                            </Col>
                            {isBusiness ? (
                                <Col span={12}>
                                    <Summary>
                                        <span>Date of incorporation:</span>
                                        <span className='value'><b>{data?.basicInformation?.dateOfIncorporation}</b></span>
                                    </Summary>
                                    <Summary>
                                        <span>Registration number:</span>
                                        <span className='value'><b>{data?.basicInformation?.registrationNumber}</b></span>
                                    </Summary>
                                    <Summary>
                                        <span>Place of incorporation:</span>
                                        <span className='value'><b>{data?.basicInformation?.placeOfIncorporation}</b></span>
                                    </Summary>
                                </Col>
                            ) : (<></>)}
                            <Col span={24}>
                                <BusinessProfileComment {...commentProps} name='addressComments' label={`${isBusiness ? 'Registered address' : 'Address'} - Bitclear comment:`} value={values.addressComments} error={errors.addressComment} />
                            </Col>
                        </Row>
                        <Divider />
                        <div className={printRender && isBusiness ? 'margin-min-print' : ''}>
		                    <Row justify='space-between'>
	                   			<Title level={3} className='sourceOfFunds'>Source of Funds (SoF) / Source of Wealth (Sow):</Title>
	                   		</Row>
                   		</div>
                   		<Row gutter={30}>
                            <Col span={24}>
                                {isV1 || isBusiness ? (
                                    <Summary>
                                        <span>Funding of business activities (SoF):</span>
                                        <span className='value'><b>{translateSoFName(data?.sourceOfFunds?.fundingOfBusinessActivities)}</b></span>
                                    </Summary>
                                ) : (<></>)}
                                <Summary>
                                    <span>Further explanation (mandatory if not by UBO):</span>
                                    <span className='value'><b>{data?.sourceOfFunds?.furtherExplanation}</b></span>
                                </Summary>
                                {isV1 ? (
                                    <Summary>
                                        <span>Effective Contributor(s) of funds:</span>
                                        <span className='value'><b>{data?.sourceOfFunds?.effectiveContributorOfFunds}</b></span>
                                    </Summary>
                                ) : (<></>)}
                                {(isV1 || (!isV1 && !isBusiness)) ? (
                                    <Summary>
                                        <span>Business background and job of the effective contributor(s) (SoW):</span>
                                        <span className='value'><b>{data?.sourceOfFunds?.businessBackgroundOfContributor}</b></span>
                                    </Summary>
                                ) : (<></>)}
                                <BusinessProfileComment {...commentProps} name='sourceOfWealthComments' label='Source of Funds - Bitclear comment:' value={values.sourceOfWealthComments} error={errors.sourceOfWealthComments} />
                            </Col>
                   		</Row>
                        <Divider />
                   		<Row justify='space-between'>
                   			<div>
                   				<Title level={3}>Purpose of Entity / Use of Funds:</Title>
                   			</div>
                   		</Row>
                  		<Row gutter={30}>
		                   	<Col span={24}>
                               {data?.purposeOfEntity?.website ? (
                                    <Summary>
                                        <span>Website:</span>
                                        <span className='value'><b>{data?.purposeOfEntity?.website}</b></span>
                                    </Summary>
                                ) : (<></>)}
                                {isBusiness || isV1 ? (
                                    <Summary>
                                        <span>Nature of business activities and business model:</span>
                                        <span className='value'><b>{data?.purposeOfEntity?.businessDescription}</b></span>
                                    </Summary>
                                ) : (<></>)}
                                {isBusiness || isV1 ? (
                                    <Summary>
                                        <span>Countries and regions of operation:</span>
                                        <span className='value'><b>{translateCountries(data?.purposeOfEntity?.countriesOfOperation)}</b></span>
                                    </Summary>
                                ) : (<></>)}
                                <Summary>
                                    <span>Approximate annual turnover (EUR):</span>
                                    <span className='value'><b>{data?.purposeOfEntity?.approximateAnnualTurnoverEur}</b></span>
                                </Summary>
                                {isBusiness || isV1 ? (
                                    <Summary>
                                        <span>Estimated amount of future Crypto transactions with Bitclear p.a. (in EUR):</span>
                                        <span className='value'><b>{data?.purposeOfEntity?.estimatedOfCryptoTransactionsEur}</b></span>
                                    </Summary>
                                ) : (<></>)}
                                {isV1 ? (
                                    <>
                                        <Summary>
                                            <span>Purpose of business relationship:</span>
                                            <span className='value'><b>{data?.purposeOfEntity?.purposeOfBusinessRelationship}</b></span>
                                        </Summary>
                                        <Summary>
                                            <span>Name(s) of main bank(s):</span>
                                            <span className='value'><b>{data?.purposeOfEntity?.banks}</b></span>
                                        </Summary>
                                    </>
                                ) : (
                                    <BusinessProfileComment {...commentProps} name='purposeOfEntityComments' label='Purpose of Entity - Bitclear comment:' value={values.purposeOfEntityComments} error={errors.purposeOfEntityComments} />
                                )}
				            </Col>
			            </Row>
                   		{isBusiness && 
                   			<>
                                <Divider />
		                   		<Title level={3}>Authorized Persons and UBOs</Title>
                                {data?.authorizedPersonsUBOs?.directors.length ? (
                                    <>
                                        <Title level={4}>Directors</Title>
                                        <Row>
                                            <Col span={24}>
                                                <DirectorsTable
                                                    list={data?.authorizedPersonsUBOs?.directors}
                                                    name='Directors'
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                ) : (<></>)}
                                {data?.authorizedPersonsUBOs?.otherAuthorizedPersons.length ? (
                                    <>
                                        <Divider />
                                        <Title level={4}>Other Authorized Persons</Title>
                                        <Row>
                                            <Col span={24}>
                                                <DirectorsTable
                                                    list={data?.authorizedPersonsUBOs?.otherAuthorizedPersons}
                                                    name='Authorized Persons' />
                                            </Col>
                                        </Row>
                                    </>
                                ) : (<></>)}
                                {data?.authorizedPersonsUBOs?.beneficialOwners.length ? (
                                    <>
                                        <Divider />
                                        <Title level={4}>Ultimate Beneficial Owners</Title>
                                        <Row>
                                            <Col span={24}>
                                                <DirectorsTable
                                                    list={data?.authorizedPersonsUBOs?.beneficialOwners}
                                                    name='Beneficial Owners'
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                ) : (<></>)}
                                <BusinessProfileComment {...commentProps} name='authorizedPersonAndUBOComments' label='Authorized Persons and UBOs - Bitclear comment:' value={values.authorizedPersonAndUBOComments} error={errors.authorizedPersonAndUBOComments} />
		                   		<br />
		                   	</>
		                }
		                <div>
		                	<Divider style={{marginTop: '-20px', marginBottom: '40px'}} />
		                	<Title level={3} className='approvalsTitle'>Business Profile Approvals</Title>
		                </div>
                  		<Row >
                  			<Col>
		                  		<ApprovalSignature 
		                  			approveUpdate={businessProfileApprove}
		                  			approveParams={{businessProfileId: snapshotId}}
		                  			isEditable={isEditable}
		                  			title="Client Advisor" 
		                  			role="CLIENT_ADVISOR" 
		                  			value={bPData.clientAdvisorApproval?.approvedBy}
		                  			time={bPData.clientAdvisorApproval?.approvedAt}
		                  			snapshotId={snapshotId} 
		                  			disabled={bPData?.complianceOfficerApproval?.approvedBy !== undefined && bPData.complianceOfficerApproval.approvedBy == adminProfile.profile.adminId}
		                  		/>
						    </Col>
						    <Col>
							    <ApprovalSignature 
							    	approveUpdate={businessProfileApprove}
							    	approveParams={{businessProfileId: snapshotId}}
							    	isEditable={isEditable}
							    	title="Compliance Officer" 
							    	role="COMPLIANCE_OFFICER" 
							    	value={bPData.complianceOfficerApproval?.approvedBy}
							    	time={bPData.complianceOfficerApproval?.approvedAt}
							    	snapshotId={snapshotId}
							    	disabled={bPData.clientAdvisorApproval?.approvedBy !== undefined && bPData.clientAdvisorApproval.approvedBy == adminProfile.profile.adminId}
							    />
						    </Col>
					    </Row>
					    {children}
                  	</>
                )
            }}

        </BasicFormWrapper>
			 
	)
}

export default BusinessProfileForm
