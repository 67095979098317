import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client";

type MessageRequest = Components.Schemas.InternalMessage;
type MessageResponse = Components.Schemas.IndividualInternalMessageEntry;

export const individualMessagePost: AsyncThunkActionType<
  MessageResponse[],
  AsyncThunkDataType<null, MessageRequest, "params">
> = createAsyncThunk("individualMessagePost", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.CreateIndividualInternalMessage(data.params, data.data, data?.config);
    return response.data as MessageResponse[];
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
