import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";
import { businessRelationGet } from "../asyncThunks/businessRelationGet";
import { businessRelationIndividualGet } from "../asyncThunks/businessRelationIndividualGet";
import { businessRelationCreate } from "../asyncThunks/businessRelationCreate";
import { businessRelationIndividualCreate } from "../asyncThunks/businessRelationIndividualCreate";
import { businessRelationArchive } from "../asyncThunks/businessRelationArchive";
import { businessRelationIndividualArchive } from "../asyncThunks/businessRelationIndividualArchive";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";

export type RelationResponse = Components.Schemas.BusinessRelation | string;

type State = {
  businessRelationCompany: RelationResponse,
  businessRelationIndividual: RelationResponse,
  newBusinessRelationCompany: RelationResponse,
  newBusinessRelationIndividual: RelationResponse,
  archivedBusinessRelation: RelationResponse,
  archivedBusinessRelationIndividual: RelationResponse
};

const emptyRelation = {
    businessRelationId: '',
    isActive: false,
    isPending: false
}

const initialState: State = {
    businessRelationCompany: emptyRelation,
    businessRelationIndividual: emptyRelation,
    newBusinessRelationCompany: emptyRelation,
    newBusinessRelationIndividual: emptyRelation,
    archivedBusinessRelation: emptyRelation,
    archivedBusinessRelationIndividual: emptyRelation
};

type CaseReducers<State> = {
    businessRelationGetClear: CaseReducerType<State, object>;
    businessRelationIndividualGetClear: CaseReducerType<State, object>;
    businessRelationCreateClear: CaseReducerType<State, object>;
    businessRelationIndividualCreateClear: CaseReducerType<State, object>;
    businessRelationArchiveClear: CaseReducerType<State, object>;
    businessRelationIndividualArchiveClear: CaseReducerType<State, object>;
};

export const businessRelationSlice = createSlice<State, CaseReducers<State>>({
  name: "businessRelation",
  initialState,
  reducers: {
    businessRelationGetClear: (state, { payload }) => {
        state.businessRelationCompany = initialState.businessRelationCompany
    },
    businessRelationIndividualGetClear: (state, { payload }) => {
        state.businessRelationIndividual = initialState.businessRelationIndividual
    },
    businessRelationCreateClear: (state, { payload }) => {
        state.newBusinessRelationCompany = initialState.newBusinessRelationCompany
    },
    businessRelationIndividualCreateClear: (state, { payload }) => {
        state.newBusinessRelationIndividual = initialState.newBusinessRelationIndividual
    },
    businessRelationArchiveClear: (state, { payload }) => {
        state.archivedBusinessRelation = initialState.archivedBusinessRelation
    },
    businessRelationIndividualArchiveClear: (state, { payload }) => {
        state.archivedBusinessRelationIndividual = initialState.archivedBusinessRelationIndividual
    },
  },
  extraReducers: (builder) => {
    builder.addCase(businessRelationGet.fulfilled, (state, action) => {
        state.businessRelationCompany = action.payload
    });
    builder.addCase(businessRelationIndividualGet.fulfilled, (state, action) => {
        state.businessRelationIndividual = action.payload
    });
    builder.addCase(businessRelationCreate.fulfilled, (state, action) => {
        state.newBusinessRelationCompany = action.payload
    });
    builder.addCase(businessRelationIndividualCreate.fulfilled, (state, action) => {
        state.newBusinessRelationIndividual = action.payload
    });
    builder.addCase(businessRelationArchive.fulfilled, (state, action) => {
        state.archivedBusinessRelation = action.payload
    });
    builder.addCase(businessRelationIndividualArchive.fulfilled, (state, action) => {
        state.archivedBusinessRelationIndividual = action.payload
    });
  },
});

export const { businessRelationGetClear, businessRelationIndividualGetClear, businessRelationCreateClear, businessRelationIndividualCreateClear, businessRelationArchiveClear, businessRelationIndividualArchiveClear }  = businessRelationSlice.actions;
export default businessRelationSlice.reducer;
