import { Components } from '../../../../api/kyc/generated/client2'
import { BusinessProfileResponse } from '../../../../store/kyc-backend/asyncThunks/businessProfilesGet'
type ProfileSnapshot = Components.Schemas.ProfileSnapshot
type ProfileSnapshotV1 = Components.Schemas.ProfileSnapshotV1
type CompanyProfileSnapshot = Components.Schemas.CompanyProfileSnapshot
type IndividualProfileSnapshot = Components.Schemas.IndividualProfileSnapshot

export type Profile<T extends ProfileSnapshot = ProfileSnapshot> = Omit<BusinessProfileResponse, 'data'> & { data?: T }
export const isV1ProfileSnapshot = (profile: Profile<any>): profile is Profile<ProfileSnapshotV1> => profile.formVersion === 'V1'
export const isV2CompanyProfileSnapshot = (profile: Profile<any>): profile is Profile<CompanyProfileSnapshot> => profile.formVersion === 'V2' && !!profile.data?.authorizedPersonsUBOs
export const isV2IndividualProfileSnapshot = (profile: Profile<any>): profile is Profile<IndividualProfileSnapshot> => profile.formVersion === 'V2' && !isV1ProfileSnapshot(profile) && !isV2CompanyProfileSnapshot(profile)